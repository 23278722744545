//**********************************************
//***** DECLARATIONS
//**********************************************

//------- Couleurs -------//

// Ne changent jamais :
@blanc: #fff;
@gris-tresclair: #f5f5f5;
@gris-clair: #ccc;
@gris-moyenclair: #999;
@gris-fonce: #333;
@noir: #000;

// Couleurs réseaux sociaux :
@facebook: #3B5998;
@twitter: #00ACED;
@instagram: #517FA4;
@youtube: #BB0000;
@linkedin: #007BB6;
@google: #DD4B39;

// Couleurs supplémentaires :
@rouge: #AA102A;
@bleu-ciel: #66afe9;
@rose: #d861a3;
@violet: #ac58c7;
@vert: #4ebd9f;
@jaune: #ffad2e;
@bleu: #4d76bb;
@orange: #ec837b;

// Couleurs spécifiques :
@texte-principal: #444;

// Précisions : pour faire en rgba mettre comme ça : fade(@noir, 70%); => (0,0,0,.7)


//------- Fonts -------//

@font-base: 'Rubik', sans-serif;
@font-spe: 'Chelsea Market', cursive;

//------- Classes abstraites -------//

.border-radius (@radius) {
	-webkit-border-radius: @radius;
	-moz-border-radius:    @radius;
	border-radius:         @radius;

	-moz-background-clip:    padding;
	-webkit-background-clip: padding-box;
	background-clip:         padding-box;
}
// Ex utilisation :
//#id {
//	.border-radius(10px);
//}

.box-shadow (@x, @y, @blur, @spread, @r, @v, @b, @alpha) {
	-webkit-box-shadow:	@x @y @blur @spread rgba(@r, @v, @b, @alpha);
	-moz-box-shadow:	@x @y @blur @spread rgba(@r, @v, @b, @alpha);
	box-shadow:		@x @y @blur @spread rgba(@r, @v, @b, @alpha);
}
.opacity (@opacity) {
	-webkit-opacity: 	@opacity;
	-moz-opacity: 		@opacity;
	opacity: 		@opacity;
}
.text-shadow (@string: 0 1px 2px rgba(0, 0, 0, 0.6)) {
	text-shadow: @string;
}
.transition (@time) {
	-webkit-transition: all @time ease-in-out;
  	-moz-transition: all @time ease-in-out;
 	-o-transition: all @time ease-in-out;
  	transition: all @time ease-in-out;
}
.border-radius (@radius) {
	-webkit-border-radius: @radius;
	-moz-border-radius:    @radius;
	border-radius:         @radius;

	-moz-background-clip:    padding;
	-webkit-background-clip: padding-box;
	background-clip:         padding-box;
}

.calc(@prop; @val) {
  @{prop}: calc(~'@{val}');
  @{prop}: -moz-calc(~'@{val}');
  @{prop}: -webkit-calc(~'@{val}');
  @{prop}: -o-calc(~'@{val}');
}



/************************************************************
******** GENERAL
************************************************************/

::-moz-selection { color: @blanc;  background: @noir; }
::-o-selection { color: @blanc;  background: @noir; }
::-webkit-selection { color: @blanc;  background: @noir; }
::selection      { color: @blanc;  background: @noir; } 

body {
	font-family:@font-base;
	color:@texte-principal;
}

/*Titres, paragraphes, liens */
a, input, button {
	.transition(0.2s);
}
a {
    color:@bleu;
    &:hover {
        color:@orange;
        text-decoration: none;
    }
}

h1,h2,h3,h4,h5,h6 {margin:0;padding:0;}

b, strong {font-weight:500;}

/* Boutons */
.btn {
	display: inline-block;
	min-width:180px;
	padding: 20px 25px;
    border-radius: 50% ~"/" 15%;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
	margin-left: 10px;
    z-index: 1;
    font-size: 24px;
	line-height: 1.42857143;
	font-weight: 500;
    text-transform: capitalize;
    text-align: center;
    white-space: normal;
    vertical-align: middle;
    cursor: pointer;
    -ms-touch-action: manipulation;
        touch-action: manipulation;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-image: none;
    border: 0px solid transparent;
	filter: drop-shadow(0 5px 10px rgba(245, 149, 142, 0.3));
	@media screen and (max-width: 991px) {
		min-width:160px;
		font-size:18px;
		padding:18px 19px;
	}
	&:before {
		content: '';
		position: absolute;
		border-radius: 3% ~"/" 50%;
		background: inherit;
		top: 10px;
		bottom: 10px;
		right: -5px;
		left: -5px;
		z-index: -1;
		@media screen and (max-width: 991px) {
			right: -4px;
			left: -4px;
			top:8px;
			bottom:8px;
		}
	}
	&:focus, &.focus, &:hover {
		padding: 23px 25px;
		border-radius: 50% ~"/" 18%;
		margin-top: 7px;
		margin-bottom: 7px;
		@media screen and (max-width: 991px) {
			padding:21px 19px;
			margin-top: 4px;
			margin-bottom: 4px;
		}
		&:before {
			border-radius: 6% ~"/" 50%;
			top: 11px;
			bottom: 11px;
			right: -9px;
			left: -9px;
			@media screen and (max-width: 991px) {
				right: -10px;
				left: -10px;
			}
		}
	}
}
.btn {
	// BTN par défaut
	&-default{
		color: @blanc;
  		background-color: @bleu;
  		border-color: @bleu;
		&:focus, &.focus, &:hover {
			color: @blanc;
			background:@rose;
		}
	}
	// BTN inverse par défaut
	&-inverse{
		color: @blanc;
  		background-color: @vert;
  		border-color: @vert;
		&:focus, &.focus, &:hover {
			color: @blanc;
			background:@jaune;
			
		}
	}
}

/* Réseaux sociaux */
.social-networks {
	margin-top:15px;
	.sn {
		display:inline-block;
		width:50px;
		height:50px;
		border-radius:50px;
		text-align:center;
		font-size:20px;
		background:@blanc;
		color:@bleu !important;
		margin:1px;
		i {
			line-height:51px !important;
		}
		&:hover {
			background:@jaune;
			color:@blanc !important;
		}
	}
	/* Background gradient hover instagram
	.sn-instagram {
		background: #d6249f;
		background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
		color:@blanc;
		&:hover {
			background: radial-gradient(circle at 30% 107%, @vert 0%, @vert 5%, @vert 45%,@vert 60%,@vert 90%);
		}
	}
	*/
}

/* Bloc texte informatif */
.blockDashed {
	text-align: center;
	margin: 10px;
	padding: 20px;
	border: 1px grey dashed;
	background: fade(@noir, 6%);
}

/* Horaires */
.week-hours {
	.day {
		background: @blanc;
		padding: 8px 0;
		font-size: 16px;
		@media screen and (max-width: 350px) {
			font-size:14px;
		}
		span {
			width: 120px;
			display: inline-block;
			font-weight: 700;
			@media screen and (max-width: 991px) {
				width:90px;
			}
			@media screen and (max-width: 350px) {
				width:80px;
			}
		}
	}
	.day.active {
		font-family: @font-spe;
		font-size:20px;
		background: @bleu !important;
		color: @blanc;
		border-radius:4px;
		padding:8px;
		@media screen and (max-width: 991px) {
			font-size:15px;
		}
		@media screen and (max-width: 350px) {
			font-size:13px;
		}
		span {font-weight: normal;}
	}
}

/* Colonnes hauteur égale */
.eqWrap {
    display: flex;
    flex-wrap: wrap;
}

/* Margin */
.margin-top5 {margin-top:5px !important;}
.margin-top10 {margin-top:10px !important;}
.margin-top15 {margin-top:15px !important;}
.margin-top20 {margin-top:20px !important;}
.margin-top25 {margin-top:25px !important;}
.margin-top30 {margin-top:30px !important;}
.margin-top35 {margin-top:35px !important;}
.margin-top40 {margin-top:40px !important;}
.margin-top45 {margin-top:45px !important;}
.margin-top50 {margin-top:50px !important;}
.margin-bottom5 {margin-bottom:5px !important;}
.margin-bottom10 {margin-bottom:10px !important;}
.margin-bottom15 {margin-bottom:15px !important;}
.margin-bottom20 {margin-bottom:20px !important;}
.margin-bottom25 {margin-bottom:25px !important;}
.margin-bottom30 {margin-bottom:30px !important;}
.margin-bottom35 {margin-bottom:35px !important;}
.margin-bottom40 {margin-bottom:40px !important;}
.margin-bottom45 {margin-bottom:45px !important;}
.margin-bottom50 {margin-bottom:50px !important;}

/* Visible/Hidden */
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg,
.visible-xl {
  display: none !important;
}

@media (max-width: 575px) {
  .visible-xs {
    display: block !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .visible-sm {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-md {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-lg {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-xl {
    display: block !important;
  }
}
@media (max-width: 575px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-lg {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-xl {
    display: none !important;
  }
}

/** Vidéos responsive **/
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
}
.video-container iframe,  
.video-container object,  
.video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Color Modal */
.modal-backdrop {
	background-color:@noir;
}
.modal-backdrop.show {
	opacity:.7;
}

/** Tooltip **/
.tooltip.show {
	opacity:1;
}
.tooltip-inner {
    background-color: @gris-fonce;
}
.bs-tooltip-right .arrow:before {
    border-right-color: @gris-fonce !important;
}
.bs-tooltip-left .arrow:before {
    border-left-color: @gris-fonce !important;
}
.bs-tooltip-bottom .arrow:before {
    border-bottom-color: @gris-fonce !important;
}
.bs-tooltip-top .arrow:before {
    border-top-color: @gris-fonce !important;
}

/** SFBadminbar **/
#sfbadminbar-body {
    margin-top: 0!important;
}

#sfbadminbar-body .position-sticky {
    top:32px;
}

/** ReCaptcha V3 hidden **/
// .grecaptcha-badge{display: none;}

/* Small Devices, Tablets annule animations */
@media only screen and (max-width : 768px) {
	.animated {
		/*CSS transitions*/
		-o-transition-property: none !important;
		-moz-transition-property: none !important;
		-ms-transition-property: none !important;
		-webkit-transition-property: none !important;
		transition-property: none !important;
		/*CSS transforms*/
		-o-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
		/*CSS animations*/
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;
	}
}

/************************************************************
******** STYLE DES IMAGES
************************************************************/

.img {
	&-left {
		float:left;
		margin-right:10px;
		margin-bottom:10px;
		max-width:250px;
		height:auto !important;
	}
	&-right {
		float:right;
		margin-left:10px;
		margin-bottom:10px;
		max-width:250px;
		height:auto !important;
	}
	&-inline {
		display:inline-block;
		width:32% !important;
		margin:0.5%;
		height:auto !important;
	}
	&-responsive {
		display: block;
	  	max-width: 100%;
	  	margin:0 auto;
		height:auto !important;
	}
}

@media screen and (max-width: 768px) {
    .img {
		&-inline {
			display:block;
			width:100%;
			margin:0 auto;
			max-width:400px;
		}
    }
}

@media screen and (max-width: 450px) {
    .img {
		&-left {
			float:none;
			margin-right:0;
			margin-bottom:15px;
			max-width:100%;
		}
		&-right {
			float:none;
			margin-left:0;
			margin-bottom:15px;
			max-width:100%;
		}
    }
}


/***********************************************************
******* ATTENTE
***********************************************************/

#attente {
	padding:60px 0;
	h1 {
		font-size:30px;
		line-height:32px;
	}
	h2 {
		font-size:20px;
		line-height:22px;
		color:@bleu;
		margin-bottom:10px;
		margin-top:40px;
	}
}

/***********************************************************
******* HEADER
***********************************************************/

#header {
	.info {
		@media screen and (max-width: 767px) {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
  			justify-content: center;
		}
		&:hover {
			.icon {
				height: 74px;
				line-height: 74px;
				width: 64px;
				border-radius: 50% ~"/" 10%;
				margin-top: 3px;
				margin-bottom: 3px;
				@media screen and (max-width: 991px) {
					height: 44px;
					line-height: 44px;
					width: 34px;
				}
				&:after {
					top: 6px;
					bottom: 6px;
					left: -6px;
					right: -6px;
					border-radius: 10% ~"/" 50%;
					@media screen and (max-width: 991px) {
						top: 3px;
						bottom: 3px;
						left: -3px;
						right: -3px;
					}
				}
			}
		}
		.icon {
			font-size: 25px;
			height: 70px;
			line-height: 70px;
			width: 64px;
			border-radius: 50% ~"/" 10%;
			display: inline-block;
			text-align: center;
			position: relative;
			z-index: 1;
			color: #fff;
			margin-top: 5px;
			margin-bottom: 5px;
			.transition(0.2s);
			@media screen and (max-width: 991px) {
				font-size: 16px;
				height: 40px;
				line-height: 40px;
				width: 34px;
			}
			@media screen and (max-width: 575px) {
				display:none;
			}
			&:after {
				content: '';
				position: absolute;
				top: 6px;
				bottom: 6px;
				left: -4px;
				right: -4px;
				background: inherit;
				border-radius: 6% ~"/" 50%;
				z-index: -1;
				@media screen and (max-width: 991px) {
					top: 3px;
					bottom: 3px;
					left: -2px;
					right: -2px;
				}
			}
		}
		.text {
			margin-top:10px;
			font-size:16px;
			line-height: 18px;
			@media screen and (max-width: 767px) {
				margin-top:0;
				margin-left:10px;
				text-align:left;
				font-size:14px;
				line-height: 16px;
			}
			@media screen and (max-width: 575px) {
				text-align:center;
				font-size:22px;
				line-height:22px;
				font-family: @font-spe;
				margin-left:0;
				margin-top:6px;
			}
			a {
				display: block;
				color:@texte-principal;
				
			}
		}
		.text a:nth-child(2) {
			@media screen and (max-width: 575px) {
				display:none;
			}
		}
	}
	.info.bg-vert {
		.icon {
			background:@vert;	
		}
	}
	.info.bg-rose {
		.icon {
			background:@rose;	
		}
	}
	.info.bg-rose,
	.info.bg-vert {
		&:hover {
			.icon {
				background:@bleu;
			}
		}
		.text {
			a {
				&:hover {
					color:@bleu;
				}
			}
		}
	}
	a.logo {
		display:block;
		width:300px;
		margin:10px auto 0 auto;
		@media screen and (max-width: 1199px) {
			width:280px;
		}
		@media screen and (max-width: 991px) {
			width:210px;
		}
		@media screen and (max-width: 767px) {
			width:150px;
		}
	}
}

/***********************************************************
******* MENU NAV BOOTSTRAP
***********************************************************/

.navbar.position-sticky {
	top:0;
	z-index:9999;
}
.navbar-light {
	background:@blanc;
	border-bottom:4px solid fade(@orange,40%);
	.navbar-brand {
		/* Par défaut caché, voir media queries plus bas */
		padding-left:10px;
	}
	.navbar-nav {
		.nav-link {
			color: @gris-fonce;
			font-weight:500;
			span.bg-link {
				position: relative;
				padding: 2px 10px;
				display: inline-block;
				z-index: 9;
				&:after {
					content: '';
					display: inline-block;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					position: absolute;
					-webkit-border-radius: 4px;
					-moz-border-radius: 4px;
					border-radius: 4px;
					z-index: -1;
					background: @bleu;
					-webkit-transition: -webkit-transform .4s ease;
					-o-transition: -o-transform .4s ease;
					-moz-transition: transform .4s ease,-moz-transform .4s ease;
					transition: transform .4s ease;
					transition: transform .4s ease,-webkit-transform .4s ease,-moz-transform .4s ease,-o-transform .4s ease;
					-webkit-transform: scaleY(0);
					-moz-transform: scaleY(0);
					-ms-transform: scaleY(0);
					-o-transform: scaleY(0);
					transform: scaleY(0);
				}
			}
			&:hover {
				color:@blanc;
				span.bg-link {
					&:after {
						-webkit-transform: scaleY(1);
						-moz-transform: scaleY(1);
						-ms-transform: scaleY(1);
						-o-transform: scaleY(1);
						transform: scaleY(1)
					}
				}
			}
		}
		.nav-link.dropdown-toggle:hover,
		.show>.nav-link.dropdown-toggle {
			&:after {
				color:@bleu;
			}
		}
		.show>.nav-link {
			color:@blanc;
			span.bg-link {
				&:after {
					-webkit-transform: scaleY(1);
					-moz-transform: scaleY(1);
					-ms-transform: scaleY(1);
					-o-transform: scaleY(1);
					transform: scaleY(1)
				}
			}
		}
		.active {
			color:@bleu;
			font-weight:700;
			
			&:hover .nav-link {
				color:@bleu !important;
			}
			
		}
		.active>.nav-link {
			color:@bleu;
			span.bg-link {
				&:after {
					background:@blanc;
				}
			}
		}
		.dropdown-menu {
			min-width: 10rem;
			padding: 0;
			margin: 0 0 0 8px;
			font-size: 1rem;
			color: @bleu;
			text-align: left;
			list-style: none;
			background-color: #fff;
			background-clip: padding-box;
			border: 4px solid @bleu;
			border-radius: .25rem;
			-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
			-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
			@media screen and (max-width: 767px) {
				margin:0;
			}
			.dropdown-item {
				padding: 10px 15px;
				clear: both;
				font-weight: 400;
				color: @bleu;
				text-align: inherit;
				white-space: nowrap;
				background-color: transparent;
				border: 0;
				
				&:hover {
					background:@bleu;
					color:@blanc;
				}
			}
			.dropdown-item + .dropdown-item {
				border-top:4px solid @bleu;
			}
			.active {
				color:@blanc;
                background:@bleu;

				&:hover .nav-link {
					color:@blanc !important;
                    background:@bleu !important;
				}

			}
			.active>.nav-link {
				color:@gris-fonce;
			}
		}
	}
	.navbar-toggler {
		border-color: @gris-clair;
	}
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(51,51,51, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}

/** Fonction faire disparaitre le logo de la navbar puis réapparaitre en mode burger **/
@media screen and (min-width: 768px) {
	.navbar-light {
		.navbar-brand {
			display:none;
		}
	}
}

/** Fonction Hover Menu **/
/*
.dropdown:hover > .dropdown-menu {
    display: block;
}
.dropdown > .dropdown-toggle:active {
    pointer-events: none;
}
*/

/***********************************************************
******* CAROUSEL BOOTSTRAP
***********************************************************/

/** Pour l'exemple : suppr ensuite **/
.skyblue {
	background-color: #22c8ff;
}
.deepskyblue {
	background-color: #00bfff;
}
.darkerskyblue {
	background-color: #00a6dd;
}
.carousel-caption h3,
.carousel .icon-container,
.carousel-caption button {
	background-color: #09c;
}
.carousel-caption h3 {
	padding: .5em;
}
.carousel .icon-container {
	display: inline-block;
	font-size: 25px;
	line-height: 25px;
	padding: 1em;
	text-align: center;
	
	border-radius: 50%;
}
.carousel-caption button {
	border-color: #00bfff;
	margin-top: 1em; 
}

/** Carousel indicators & Carousel control **/
.carousel-indicators {
	bottom: 0;
	cursor:pointer;
}
.carousel-control.right,
.carousel-control.left {
	background-image: none;
}

/** Carousel items **/
.carousel {
	.carousel-item {
		min-height: 350px; 
		height: 100%;
		width:100%; 
	}
}

/*** Fade effect ***/
// ajouter carousel-fade au même niveau que class="carousel slide" sur le front
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
} 
@media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-fade .carousel-inner > .item.next,
    .carousel-fade .carousel-inner > .item.active.right {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.prev,
    .carousel-fade .carousel-inner > .item.active.left {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.next.left,
    .carousel-fade .carousel-inner > .item.prev.right,
    .carousel-fade .carousel-inner > .item.active {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
} 

/* Animation delays */
.carousel-caption h3:first-child,
.carousel-caption .slider-title {
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
.carousel-caption h3:nth-child(2),
.carousel-caption .slider-caption{
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}
.carousel-caption .btn-default {
	-webkit-animation-delay: 1.5s;
	animation-delay: 1.5s;
}
.carousel-caption .btn-inverse {
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
}

#slider_home {
	.carousel-item {
		position: relative;
		height:650px;
		@media screen and (max-width: 1399px) {
			height:500px;
		}
		@media screen and (max-width: 767px) {
			height:400px;
		}
		.carousel-caption {
			position: absolute;
			/* Si on veut centrer la légende
			top: 50%;
			transform: translateY(-50%);
			*/
			top:130px;
			right: inherit;
			width:40%;
			bottom: inherit;
			left: 10%;
			z-index: 10;
			padding-top: 0;
			padding-bottom: 0;
			color: #fff;
			text-align: left;
			@media screen and (max-width: 1399px) {
				width:55%;
				left:5%;
				top:100px;
			}
			@media screen and (max-width: 1199px) {
				top:80px;
			}
			@media screen and (max-width: 767px) {
				top:50%;
				transform: translateY(-50%);
				right:5%;
				width:auto;
			}
			.slider-title {
				display: inline-block;
				padding:10px 15px;
				font-family: @font-spe;
				font-size:55px;
				line-height: 55px;
				background:@orange;
				color:@blanc;
				margin-bottom:30px;
				border-radius:4px;
				@media screen and (max-width: 1199px) {
					font-size:45px;
					line-height: 45px;
				}
				@media screen and (max-width: 767px) {
					font-size:35px;
					line-height: 35px;
					margin-bottom:0;
					border-radius:4px 4px 0 0;
				}
				@media screen and (max-width: 450px) {
					font-size:25px;
					line-height: 25px;
				}
			}
			.slider-caption {
				font-size:25px;
				line-height:27px;
				color:@orange;
				margin-bottom:30px;
				@media screen and (max-width: 767px) {
					background:@blanc;
					padding:20px;
					margin-bottom:15px;
					font-size:20px;
					line-height:22px;
					border-radius:0 0 4px 4px;
				}
				@media screen and (max-width: 450px) {
					font-size:16px;
					line-height:18px;
				}
			}
			.btn-slider {
				display: inline-block;
				padding: 20px 25px;
				border-radius: 50% ~"/" 15%;
				position: relative;
				margin-top: 10px;
				margin-bottom: 10px;
				margin-right: 15px;
				color: #fff;
				z-index: 1;
				font-size: 24px;
				text-transform: capitalize;
				transition: all linear 0.1s;
				-o-transition: all linear 0.1s;
				-moz-transition: all linear 0.1s;
				-webkit-transition: all linear 0.1s;
				margin-left: 5px;
				cursor: pointer;
				filter: drop-shadow(0 5px 10px rgba(132, 194, 235, 0.3));
				background:red;
				&:before {
					content: '';
					position: absolute;
					border-radius: 3% ~"/" 50%;
					background: inherit;
					top: 10px;
					bottom: 10px;
					right: -5px;
					left: -5px;
					z-index: -1;
					transition: all linear 0.1s;
					-o-transition: all linear 0.1s;
					-moz-transition: all linear 0.1s;
					-webkit-transition: all linear 0.1s;
				}
				&:hover {
					padding: 23px 25px;
					border-radius: 50% ~"/" 18%;
					margin-top: 7px;
					margin-bottom: 7px;
					color: #fff;
					animation: rainbow 5s infinite alternate;
					-moz-animation: rainbow 5s infinite alternate;
					-webkit-animation: rainbow 5s infinite alternate;
					-o-animation: rainbow 5s infinite alternate;
					background:blue;
					&:before {
						border-radius: 6% ~"/" 50%;
						top: 11px;
						bottom: 11px;
						right: -9px;
						left: -9px;
						animation: rainbow 5s infinite alternate;
						-moz-animation: rainbow 5s infinite alternate;
						-webkit-animation: rainbow 5s infinite alternate;
						-o-animation: rainbow 5s infinite alternate;
					}
				}
			}
		}
		.content {
			display:flex;
			flex-wrap: nowrap;
			height:100%;
			.left {
				background:#f7cdca;
				width:40%;
				height:100%;
				@media screen and (max-width: 1399px) {
					width:60%;
				}
			}
			.right {
				position: relative;
				background:url('../img/big_img_test.jpg')no-repeat center center;
				background-size:cover;
				width:60%;
				height:100%;
				@media screen and (max-width: 1399px) {
					width:40%;
				}
				&:before {
					content:'';
					position: absolute;
					top:0;
					left:0;
					right:0;
					bottom:0;
					background:url('../img/slider-mask.png')no-repeat left top;
					background-size:cover;
				}
			}
		}
	}
}

/***********************************************************
******* PRESENTATION HOME
***********************************************************/

#presentation-home {
	padding:100px 0;
	@media screen and (max-width: 575px) {
		padding:60px 0;
	}
	h1 {
		font-size:25px;
		line-height: 27px;
		margin-bottom:20px;
		span {
			display:table;
			margin-bottom:15px;
			font-family: @font-spe;
			background:@orange;
			color:@blanc;
			padding: 10px 15px;
			border-radius: 4px;
			font-size:35px;
			line-height: 35px;
		}
	}
	.img-form {
		border-radius: 50% ~"/" 10%;
    	clip-path: inset(0% 0% 0% 0% round 8% ~"/" 50%);
		@media screen and (max-width: 767px) {
			margin-top:30px;
		}
	}
	.text {
		p {
			&:last-child {
				margin-bottom:0;
			}
		}
	}
}

/***********************************************************
******* ACTUALITES HOME
***********************************************************/

#actualites-home {
	padding:100px 0;
	color:@blanc;
	background:url(../img/big_img_test.jpg) no-repeat;
	background-size:cover;
	position:relative;
	@media screen and (max-width: 575px) {
		padding:60px 0;
	}
	/** Correctif fixed sur version mobile **/
	@media only screen and (min-width: 992px) {
		background-attachment: fixed;
	}
	.container {
		position: relative;
		z-index:9;
	}
	h2 {
		font-size:25px;
		line-height: 27px;
		margin-bottom:20px;
		span {
			display:table;
			margin-bottom:15px;
			font-family: @font-spe;
			color:@orange;
			background:@blanc;
			padding: 10px 15px;
			border-radius: 4px;
			font-size:35px;
			line-height: 35px;
		}
	}
	.tiny-actus {
		color:@texte-principal;
		.item {
			a.content {
				position: relative;
				display: block;
				color:@texte-principal;
				min-height:300px;
				background:@blanc;
				border-radius: 50% ~"/" 10%;
				clip-path: inset(0% 0% 0% 0% round 8% ~"/" 50%);	
				padding:30px;
				text-align:center;
				@media screen and (max-width: 767px) {
					min-height:inherit;
				}
				&:hover {
					.date {
						top:16px;
						height: 114px;
						border-radius: 50% ~"/" 14%;
						padding-top: 24px;
						background:@orange;
						&:before {
							top: 11px;
							bottom: 11px;
							left: -10px;
							right: -10px;
							border-radius: 12% ~"/" 50%;
							background:@orange;
						}
					}
					p.info {
						color:@orange;
					}
				}
				.date {
					position: absolute;
					z-index:2;
					top:20px;
					left: 50%;
					width: 106px;
					height: 106px;
					border-radius: 50% ~"/" 10%;
					text-align: center;
					margin: 3px 0 0 3px;
					margin-left:-53px;
					padding-top: 20px;
					font-family: @font-spe;
					font-size:40px;
					line-height: 35px;
					color:@blanc;
					background:@bleu;
					.transition(0.2s);
					&:before {
						background:@bleu;
						top: 8px;
						bottom: 8px;
						left: -6px;
						right: -6px;
						content: '';
						position: absolute;
						border-radius: 7% ~"/" 50%;
						z-index: -1;
						.transition(0.2s);
					}
					span {
						display:block;
						font-size:30px;
					}
				}
				h3 {
					padding-top:114px;
					font-size:20px;
					line-height:26px;
					margin-bottom:15px;
				}
				p.info {
					color:fade(@texte-principal,80%);
					font-size:14px;
					line-height:16px;
				}
			}
		}
	}
	/** Tiny controls **/
	.tns-controls {
		position: absolute;
		text-align:center;
		outline: none;
		left:-367px;
		top:240px;
		@media screen and (max-width: 1199px) {
			left:-306px;
		}
		@media screen and (max-width: 991px) {
			left:-347px;
			top:220px;
		}
		@media screen and (max-width: 767px) {
			left:inherit;
			top:-183px;
			right:15px;
		}
		@media screen and (max-width: 500px) {
			position: relative;
			text-align:left;
			top:inherit;
			right:inherit;
			margin:10px 0;
		}
	}
	.tns-controls:focus {
		outline: none
	}
	.tns-controls button {
		border: 0;
		padding:0;
		font-size:28px;
		color:@blanc;
		background:@orange;
		width:60px;
		height:60px;
		line-height:60px;
		border-radius: 60px;
		margin:2px;
		text-align: center;
		@media screen and (max-width: 767px) {
			width:40px;
			height:40px;
			line-height:40px;
			border-radius: 40px;
			font-size:20px;
		}
	}
	.tns-controls button:hover {
		color:@bleu;
		background:@blanc;
	}
	.tns-controls button:disabled {
		color:fade(@blanc,50%);
		background:@orange;
	}
	.tns-controls button:focus {
		outline: none
	}
	.parallax-black {
		margin: 0;
		padding: 0;
		width: 100%;
		background-color: fade(@bleu, 80%);
		background: fade(@bleu, 80%);
		color: fade(@bleu, 80%);
		position: absolute;
		top: 0;
		height: 100%;
	}
}

/***********************************************************
******* TAB HOME
***********************************************************/

#tab-home {
	padding:80px 0 160px 0;
	@media screen and (max-width: 575px) {
		padding:60px 0;
	}
	#infos-tab {
		.nav-item {
			.nav-link {
				padding:0 50px;
				@media screen and (max-width: 991px) {
					padding:0 40px;
				}
				@media screen and (max-width: 767px) {
					padding:0 30px;
				}
				@media screen and (max-width: 530px) {
					padding:0 15px;
				}
				@media screen and (max-width: 400px) {
					padding:0 10px;
				}
				&:hover {
					.tab-icon {
						.tab-icon-shape {
							i {
								height: 151px;
								line-height: 151px;
								border-radius: 50% ~"/" 9%;
								margin-top: 3px;
								margin-bottom: 3px;
								background:@bleu !important;
								filter: drop-shadow(0 0 20px fade(@bleu,30%)) !important;
								@media screen and (max-width: 767px) {
									height: 121px;
									line-height: 121px;
								}
								@media screen and (max-width: 530px) {
									width:auto;
									height:inherit;
									border-radius:0;
									margin:0;
									line-height:75px;
									background:@blanc !important;
									filter: drop-shadow(0 0 0 fade(@vert,0%)) !important;
									color:@bleu !important;
								}
								&:after {
									top: 11px;
									bottom: 11px;
									left: -12px;
									right: -12px;
									background: inherit;
									border-radius: 9% ~"/" 50%;
									@media screen and (max-width: 767px) {
										top: 8px;
										bottom: 8px;
										left: -9px;
										right: -9px;
									}
									@media screen and (max-width: 530px) {
										display:none;
									}
								}
							}
						}
						span {
							color:@bleu !important;
						}
					}
				}
				.tab-icon {
					text-align:center;
					.tab-icon-shape {
						i {
							font-size: 75px;
							line-height: 145px;
							width: 135px;
							height: 145px;
							display: inline-block;
							color: @blanc;
							border-radius: 50% ~"/" 8%;
							position: relative;
							margin-top: 6px;
							margin-bottom: 6px;
							background: @vert;
    						filter: drop-shadow(0 0 20px fade(@vert,30%));
							@media screen and (max-width: 767px) {
								font-size: 55px;
								line-height: 115px;
								width: 105px;
								height: 115px;	
							}
							@media screen and (max-width: 530px) {
								width:auto;
								height:inherit;
								border-radius:0;
								margin:0;
								line-height:75px;
								background:@blanc !important;
								filter: drop-shadow(0 0 0 fade(@vert,0%));
							}
							&:after {
								top: 11px;
								bottom: 11px;
								left: -10px;
								right: -10px;
								background: inherit;
								border-radius: 7% ~"/" 50%;
								position: absolute;
								content: '';
								z-index: -1;
								@media screen and (max-width: 767px) {
									top: 8px;
									bottom: 8px;
									left: -7px;
									right: -7px;
								}
								@media screen and (max-width: 530px) {
									display:none;
								}
							}
						}
					}
					span {
						font-family: @font-spe;
						font-size: 22px;
						text-transform: capitalize;
						line-height: 26px;
						padding-top: 5px;
						display: inline-block;
						color: @vert;
						@media screen and (max-width: 767px) {
							font-size: 16px;
							line-height: 20px;
						}
					}
				}
				.tab-icon.bg-rose {
					.tab-icon-shape {
						i {
							background:@rose;
							filter: drop-shadow(0 0 20px fade(@rose,30%));
							@media screen and (max-width: 530px) {
								background:@blanc;
								color:@rose;
								filter: drop-shadow(0 0 0 fade(@rose,0%));
							}
						}
					}
					span {color:@rose;}
				}
				.tab-icon.bg-vert {
					.tab-icon-shape {
						i {
							background:@vert;
							filter: drop-shadow(0 0 20px fade(@vert,30%));
							@media screen and (max-width: 530px) {
								background:@blanc;
								color:@vert;
								filter: drop-shadow(0 0 0 fade(@vert,0%));
							}
						}
					}
					span {color:@vert;}
				}
				.tab-icon.bg-jaune {
					.tab-icon-shape {
						i {
							background:@jaune;
							filter: drop-shadow(0 0 20px fade(@jaune,30%));
							@media screen and (max-width: 530px) {
								background:@blanc;
								color:@jaune;
								filter: drop-shadow(0 0 0 fade(@jaune,0%));
							}
						}
					}
					span {color:@jaune;}
				}
			}
			.nav-link.active {
				.tab-icon {
					.tab-icon-shape {
						i {
							height: 151px;
							line-height: 151px;
							border-radius: 50% ~"/" 9%;
							margin-top: 3px;
							margin-bottom: 3px;
							background:@orange !important;
							filter: drop-shadow(0 0 20px fade(@orange,30%)) !important;
							@media screen and (max-width: 767px) {
								height: 121px;
								line-height: 121px;
							}
							@media screen and (max-width: 530px) {
								width:auto;
								height:inherit;
								border-radius:0;
								margin:0;
								line-height:75px;
								background:@blanc !important;
								filter: drop-shadow(0 0 0 fade(@vert,0%)) !important;
								color:@orange !important;
							}
							&:after {
								top: 11px;
								bottom: 11px;
								left: -12px;
								right: -12px;
								background: inherit;
								border-radius: 9% ~"/" 50%;
								@media screen and (max-width: 767px) {
									top: 8px;
									bottom: 8px;
									left: -9px;
									right: -9px;
								}
								@media screen and (max-width: 530px) {
									display:none;
								}
							}
						}
					}
					span {
						color:@orange !important;
					}
				}
			}
		}
	}
	#infos-tab-content {
		.tab-pane {
			padding-top:60px;
			.col-md-6 + .col-md-6 {
				@media screen and (max-width: 767px) {
					margin-top:20px;
				}
			}
		}
		.subtitle {
			font-weight:500;
			margin-bottom:16px;
		}
		h2 {
			font-size:32px;
			line-height:34px;
			@media screen and (max-width: 991px) {
				font-size:28px;
				line-height:30px;
			}
		}
		h2 + p,
		h2 + .gmap-iframe,
		h2 + ul.coordonnees-tab {
			margin-top:16px;
		}
		p {
			&:last-child {
				margin-bottom:0;
			}
		}
		.gmap-iframe {
			width:90%;
			height:200px;
			margin:0 auto;
			border-radius:4px 4px 0 0;
			overflow: hidden;
			@media screen and (max-width: 991px) {
				width:100%;
			}
			iframe {
				width:100%;
				height:100%;
			}
		}
		ul.coordonneees-tab {
			background:@gris-tresclair;
			width:90%;
			margin:0 auto 16px auto;
			padding:16px 0;
			list-style: none;
			border-radius:0 0 4px 4px;
			@media screen and (max-width: 991px) {
				width:100%;
			}
			li {
				font-size:16px;
				line-height:28px;
				padding:0 10px;
				a {
					color:@texte-principal;
					&:hover {
						color:@orange;
					}
				}
			}
		}
	}
}

/***********************************************************
******* INT
***********************************************************/

#int {
	padding:60px 0 140px 0;
	@media screen and (max-width: 575px) {
		padding:60px 0;
	}
	h1 {
		font-size:24px;
		line-height:30px;
		margin-bottom:40px;
		font-style: italic;
		span {
			display: table;
			margin-bottom: 15px;
			font-family: @font-spe;
			color: @blanc;
			background: @bleu;
			padding: 10px 15px;
			border-radius: 4px;
			font-size: 35px;
			line-height: 35px;
			font-style:normal;
		}
	}
	h2 {
		font-family: @font-spe;
		color:@bleu;
		font-size:25px;
		line-height: 27px;
		margin:30px 0 16px 0;
	}
	h3 {
        color:@orange;
		font-size:18px;
		line-height: 20px;
		margin:30px 0 16px 0;
	}
	h2 + h3 {margin-top:10px;}
	p + ul {margin-top:-16px;}
	a {
		color:@bleu;
		&:hover {
			color:@orange;
		}
	}
    a.retour-link {
        display:block;
        color:@orange;
        &:hover {
            color:@rose;
        }
    }
    h1 + a.retour-link {
        margin-top:-20px;
        margin-bottom:40px;
    }
    .imgcms-right {
        float:right;
        margin-left:40px;
        margin-bottom:40px;
        width:100%;
        max-width:350px;
        text-align:center;
        @media screen and (max-width: 1199px) {
            max-width:250px;
        }
        @media screen and (max-width: 575px) {
            float:none;
            margin-left:0;
            margin-bottom:20px;
        }
        img {
            border-radius: 50% ~"/" 10%;
    	    clip-path: inset(0% 0% 0% 0% round 8% ~"/" 50%);
        }
        a {
            &:hover {
                section {
                    color:@orange;
                }
            }
            section {
                font-family:@font-spe;
                margin-top:10px;
                color:@bleu;
                text-decoration: underline;
                .transition(0.2s);
                @media screen and (max-width: 575px) {
                    display:none;
                }
            }
        }
    }
    .photos-hidden {
        display:none;
    }
    a.btn-default {
        color:@blanc;
        &:hover {
            color:@blanc;
        }
    }
	#sidebar {
		.liens-utiles {
			background:@jaune;
			border-radius: 50% ~"/" 10%;
			clip-path: inset(0% 0% 0% 0% round 8% ~"/" 50%);	
			padding:30px;
			text-align:center;
			margin-bottom:30px;
			@media screen and (max-width: 991px) {
				margin-top:40px;
			}
			.title-side {
				display: inline-block;
				margin-bottom: 10px;
				font-family: @font-spe;
				color: @blanc;
				font-size: 22px;
				line-height: 22px;
			}
			ul {
				margin:0;
				padding:0;
				list-style: none;
				li {
					padding:10px 5px;
					&:last-child {
						padding:10px 5px 0 5px;
					}
					a {
						color:@blanc;
						&:hover {
							color:@bleu;
						}
					}
				}
				li + li {
					border-top:1px solid darken(@jaune,10%);
				}
			}
		}
		.horaires {
			text-align:center;
			margin-bottom:30px;
			.title-side {
				background:@rose;
			}
            h2 {
                margin-top:0 !important;
                margin-bottom:0 !important;
                font-weight: 500 !important;
                font-family:@font-base !important;
                font-size:16px !important;
                line-height:24px !important;
                color:@texte-principal !important;
            }
			.title {
				margin-bottom: 15px;
				font-weight: 500;
			}
			.day {
				padding:2px 0;
				span {
					width:100%;
				}
			}
			.day.active {
				padding:8px;
				font-size:16px;
			}
		}
		.periscolaire {
			text-align:center;
			.title-side {
				background:@vert;
			}
		}
		.horaires,
		.periscolaire {
			.title-side {
				display: block;
				width:100%;
				color:@blanc;
				border-radius: 50% ~"/" 10%;
				clip-path: inset(0% 0% 0% 0% round 8% ~"/" 50%);	
				padding:30px;
				text-align:center;
				margin-bottom:30px;
				font-family: @font-spe;
				font-size:22px;
				line-height: 26px;
				&:hover {
					background:@bleu;
				}
				i {
					display:block;
					font-size:75px;
				}
			}
		}
		.coordonnees {
			position: relative;
			display: block;
			color:@blanc;
			background:@jaune;
			border-radius: 50% ~"/" 10%;
			clip-path: inset(0% 0% 0% 0% round 8% ~"/" 50%);	
			padding:30px;
			text-align:center;
			margin-bottom:30px;
			@media screen and (max-width: 991px) {
				margin-top:40px;
			}
			h2 {
				margin-top:0 !important;
				color:@blanc !important;
			}
			.gmap-iframe {
				width:100%;
				height:150px;
				iframe {
					width:100%;
					height:100%;
				}
			}
			ul {
				padding:0;
				margin:0;
				list-style: none;
				li {
					padding:10px 5px;
					&:last-child {
						padding:10px 5px 0 5px;
					}
					span {
						display: block;
						text-transform: uppercase;
						font-weight: 500;
					}
					a {
						color:@blanc;
						&:hover {
							color:@bleu;
						}
					}
					i {
						font-size:12px;
					}
				}
				li + li {
					border-top:1px solid darken(@jaune,10%);
				}
			}
			.social-networks {
				.sn {
					background:@blanc;
					color:@jaune !important;
					&:hover {
						background:@bleu;
						color:@blanc !important;
					}
				}
			}			
		}
	}
	a.actu {
		position: relative;
		display: block;
		color:@blanc;
		min-height:280px;
		background:@bleu;
		border-radius: 50% ~"/" 10%;
		clip-path: inset(0% 0% 0% 0% round 8% ~"/" 50%);	
		padding:30px;
		text-align:center;
		margin-bottom:30px;
		@media screen and (max-width: 767px) {
			min-height:inherit;
		}
		&:hover {
			.date {
				top:16px;
				height: 114px;
				border-radius: 50% ~"/" 14%;
				padding-top: 24px;
				background:@orange;
				color:@blanc;
				@media screen and (max-width: 767px) {
					height:74px;
					padding-top:18px;
				}
				&:before {
					top: 11px;
					bottom: 11px;
					left: -10px;
					right: -10px;
					border-radius: 12% ~"/" 50%;
					background:@orange;
					@media screen and (max-width: 767px) {
						top: 8px;
						bottom: 8px;
						left: -7px;
						right: -7px;
					}
				}
			}
			p.info {
				color:@orange;
			}
		}
		.date {
			position: absolute;
			z-index:2;
			top:20px;
			left: 50%;
			width: 106px;
			height: 106px;
			border-radius: 50% ~"/" 10%;
			text-align: center;
			margin: 3px 0 0 3px;
			margin-left:-53px;
			padding-top: 20px;
			font-family: @font-spe;
			font-size:40px;
			line-height: 35px;
			color:@bleu;
			background:@blanc;
			.transition(0.2s);
			@media screen and (max-width: 767px) {
				width: 66px;
				height: 66px;
				margin-left:-33px;
				font-size:25px;
				line-height: 20px;
				padding-top:14px;
			}
			&:before {
				background:@blanc;
				top: 8px;
				bottom: 8px;
				left: -6px;
				right: -6px;
				content: '';
				position: absolute;
				border-radius: 7% ~"/" 50%;
				z-index: -1;
				.transition(0.2s);
				@media screen and (max-width: 767px) {
					top: 6px;
					bottom: 6px;
					left: -5px;
					right: -5px;
				}
			}
			span {
				display:block;
				font-size:30px;
				@media screen and (max-width: 767px) {
					font-size:20px;
				}
			}
		}
		h2 {
			padding-top:94px;
			font-size:20px;
			line-height:26px;
			margin-bottom:15px;
			color:@blanc;
			font-family: @font-base;
			@media screen and (max-width: 767px) {
				padding-top:40px;
				font-size:17px;
				line-height:21px;
			}
		}
		p.info {
			color:fade(@blanc,80%);
			font-size:14px;
			line-height:16px;
			margin-bottom:0;
		}
	}
}


/***********************************************************
******* FORMULAIRES (avec Parsley)
***********************************************************/

label {
	margin:0;
	font-family: @font-spe;
	color:@bleu;
}

textarea.form-control {height: auto;}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: @texte-principal;
  background-color: @blanc;
  background-image: none;
  border: 1px solid @gris-clair;
  .border-radius (4px);
  -webkit-box-shadow: inset 0 1px 1px fade(@noir, 0%);
          box-shadow: inset 0 1px 1px fade(@noir, 0%);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
       -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
          transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  
	&:focus {
		border-color: @bleu;
  		outline: 0;
  		-webkit-box-shadow: inset 0 1px 1px fade(@noir, 7.5%), 0 0 8px fade(@bleu, 0%);
        	box-shadow: inset 0 1px 1px fade(@noir, 7.5%), 0 0 8px fade(@bleu, 0%);
	}
	
	&::-moz-placeholder {
		color: @gris-moyenclair;
  		opacity: 1;
	}
	&:-ms-input-placeholder {
		color: @gris-moyenclair;
	}
	&::-webkit-input-placeholder {
		color: @gris-moyenclair;
	}
}

.parsley {
	&-success{
		border:solid 1px @gris-clair !important;
		background: @blanc !important;
		color: @gris-fonce !important;
	}
	&-error {
		border:solid 1px @rouge !important;
		background:fade(@rouge, 20%) !important;
		color:@noir !important;
	}
	&-errors-list {
		color:@rouge;
		list-style:none;
		padding:0;
	}
}

.red {color:@orange;list-style:none;padding:0;}

#form_contact {
	.required {
		color:@bleu;list-style:none;padding:0;
	}
	.success {
		color:@texte-principal;
	}
	
	label.required:after {
		content: " *";
		color: @orange;
		position: absolute;
		margin: 0 0 0 2px;
		font-size: 14px;
		padding: 0 5px 0 0;
	}
}

.ui-datepicker .ui-datepicker-title select {
	color: @gris-fonce;
}

.parsley-error {
	&::-webkit-input-placeholder{
		color: @gris-fonce;
	}
	&:-moz-placeholder{
		color: @gris-fonce;
	}
	&::-moz-placeholder{
		color: @gris-fonce;
	}
	&:-ms-input-placeholder{
		color: @gris-fonce;
	}
}

/***********************************************************
******* FOOTER
***********************************************************/

#footer {
	.footer-up {
		.container {
			position: relative;	
			@media screen and (max-width: 575px) {
				padding:0;
			}
		}
		.preinscription {
			position: absolute;
			top: -80px;
			width: 100%;
			background: #fff;
			border-radius: 50% ~"/" 10%;
			padding: 35px 0;
			min-height:200px;
			@media screen and (max-width: 991px) {
				min-height:180px;
			}
			@media screen and (max-width: 767px) {
				padding: 35px 0 25px 0;
			}
			@media screen and (max-width: 575px) {
				position: relative;
				top:inherit;
				margin:0;
				background:transparent;
				border-radius:0;
			}
			&:before {
				top: 20px;
				bottom: 20px;
				left: -11px;
				right: -11px;
				border-radius: 1% ~"/" 50%;
				background: #fff;
				position: absolute;
				content: '';
				@media screen and (max-width: 1199px) {
					left: -9px;
					right: -9px;
				}
				@media screen and (max-width: 991px) {
					left: -6px;
					right: -6px;
					top: 17px;
					bottom: 17px;
				}
				@media screen and (max-width: 767px) {
					left: -5px;
					right: -5px;
					top: 21px;
					bottom: 21px;
				}
				@media screen and (max-width:575px) {
					display:none;
				}
			}
			.content {
				z-index:9;
				@media screen and (max-width: 767px) {
					text-align:center !important;
				}
				.etiquette {
					color:@blanc;
					font-size:16px;
					line-height:18px;
					padding-left:10px;
				}
				.title {
					color:@blanc;
					font-size:35px;
					line-height:37px;
					font-weight:500;
					padding-left:10px;
					@media screen and (max-width: 991px) {
						font-size:28px;
						line-height:30px;
					}
					@media screen and (max-width: 767px) {
						font-size:22px;
						line-height:24px;
						padding-left:0;
						margin-bottom:10px;
					}
					@media screen and (max-width: 400px) {
						font-size:18px;
						line-height:20px;
						margin-bottom:5px;
					}
				}
				.btn-default {
					text-transform: inherit;
					background:@blanc;
					color:@orange;
					&:hover {
						background:@blanc;
						color:@bleu;
					}
				}
			}
			.preinscription-bg {
				width: ~"calc(100% - 12px)";
				height: ~"calc(100% - 12px)";
				top: 6px;
				left: 6px;
				border-radius: 50% ~"/" 10%;
				position: absolute;
				background: @orange;
				@media screen and (max-width:575px) {
					width:100%;
					left:0;
				}
				&:before {
					top: 18px;
					bottom: 18px;
					left: -11px;
					right: -11px;
					border-radius: 1% ~"/" 50%;
					background: @orange;
					position: absolute;
					content: '';
					@media screen and (max-width: 1199px) {
						left: -9px;
						right: -9px;
					}
					@media screen and (max-width: 991px) {
						left: -7px;
						right: -7px;
						top: 16px;
						bottom: 16px;
					}
					@media screen and (max-width: 767px) {
						left: -5px;
						right: -5px;
						top: 21px;
						bottom: 21px;
					}
					@media screen and (max-width:575px) {
						display:none;
					}
				}
			}
		}
	}
	.footer-down {
		background:@bleu;
		padding:190px 0 20px 0;
		color:@gris-tresclair;
		@media screen and (max-width: 575px) {
			margin-top:-30px;
			padding-top:80px;
			text-align:center;
		}
		.title-ecole {
			margin-bottom: 15px;
			font-family: @font-spe;
			color: @blanc;
			font-size: 35px;
			line-height: 35px;
			.img-responsive {
				@media screen and (min-width: 576px) {
					margin:inherit;
				}
			}
		}
		.title {
			display: table;
			margin-bottom: 15px;
			font-family: @font-spe;
			color: @bleu;
			background: #fff;
			padding: 10px 15px;
			border-radius: 4px;
			font-size: 25px;
			line-height: 25px;
			@media screen and (max-width: 991px) {
				margin-top:30px;
			}
			@media screen and (max-width: 575px) {
				display:none;
			}
		}
		p {margin-bottom:0;}
		ul {
			margin:0;
			padding:0;
			list-style: none;
			@media screen and (max-width: 575px) {
				margin-top:15px;
			}
			li {
				i {
					width:20px;
				}
			}
		}
		hr {
			margin-top:60px;
			margin-bottom:20px;
			border-top: 1px solid rgba(255,255,255,.1);
			@media screen and (max-width: 575px) {
				margin-top:40px;
			}
		}
		a.logo-footer {
			display: block;
			max-width:200px;
			margin:0 auto;
			@media screen and (max-width: 991px) {
				margin-top:30px;
			}
		}
		a {
			color:@gris-tresclair;
			&:hover {
				color:@jaune;
			}
			i {
				width:inherit !important;
			}
		}
	}
}
