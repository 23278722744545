/************************************************************
******** GENERAL
************************************************************/
::-moz-selection {
  color: #fff;
  background: #000;
}
::-o-selection {
  color: #fff;
  background: #000;
}
::-webkit-selection {
  color: #fff;
  background: #000;
}
::selection {
  color: #fff;
  background: #000;
}
body {
  font-family: 'Rubik', sans-serif;
  color: #444;
}
/*Titres, paragraphes, liens */
a,
input,
button {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a {
  color: #4d76bb;
}
a:hover {
  color: #ec837b;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
b,
strong {
  font-weight: 500;
}
/* Boutons */
.btn {
  display: inline-block;
  min-width: 180px;
  padding: 20px 25px;
  border-radius: 50% / 15%;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
  z-index: 1;
  font-size: 24px;
  line-height: 1.42857143;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
  white-space: normal;
  vertical-align: middle;
  cursor: pointer;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 0px solid transparent;
  filter: drop-shadow(0 5px 10px rgba(245, 149, 142, 0.3));
}
@media screen and (max-width: 991px) {
  .btn {
    min-width: 160px;
    font-size: 18px;
    padding: 18px 19px;
  }
}
.btn:before {
  content: '';
  position: absolute;
  border-radius: 3% / 50%;
  background: inherit;
  top: 10px;
  bottom: 10px;
  right: -5px;
  left: -5px;
  z-index: -1;
}
@media screen and (max-width: 991px) {
  .btn:before {
    right: -4px;
    left: -4px;
    top: 8px;
    bottom: 8px;
  }
}
.btn:focus,
.btn.focus,
.btn:hover {
  padding: 23px 25px;
  border-radius: 50% / 18%;
  margin-top: 7px;
  margin-bottom: 7px;
}
@media screen and (max-width: 991px) {
  .btn:focus,
  .btn.focus,
  .btn:hover {
    padding: 21px 19px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
}
.btn:focus:before,
.btn.focus:before,
.btn:hover:before {
  border-radius: 6% / 50%;
  top: 11px;
  bottom: 11px;
  right: -9px;
  left: -9px;
}
@media screen and (max-width: 991px) {
  .btn:focus:before,
  .btn.focus:before,
  .btn:hover:before {
    right: -10px;
    left: -10px;
  }
}
.btn-default {
  color: #fff;
  background-color: #4d76bb;
  border-color: #4d76bb;
}
.btn-default:focus,
.btn-default.focus,
.btn-default:hover {
  color: #fff;
  background: #d861a3;
}
.btn-inverse {
  color: #fff;
  background-color: #4ebd9f;
  border-color: #4ebd9f;
}
.btn-inverse:focus,
.btn-inverse.focus,
.btn-inverse:hover {
  color: #fff;
  background: #ffad2e;
}
/* Réseaux sociaux */
.social-networks {
  margin-top: 15px;
  /* Background gradient hover instagram
	.sn-instagram {
		background: #d6249f;
		background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
		color:@blanc;
		&:hover {
			background: radial-gradient(circle at 30% 107%, @vert 0%, @vert 5%, @vert 45%,@vert 60%,@vert 90%);
		}
	}
	*/
}
.social-networks .sn {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  text-align: center;
  font-size: 20px;
  background: #fff;
  color: #4d76bb !important;
  margin: 1px;
}
.social-networks .sn i {
  line-height: 51px !important;
}
.social-networks .sn:hover {
  background: #ffad2e;
  color: #fff !important;
}
/* Bloc texte informatif */
.blockDashed {
  text-align: center;
  margin: 10px;
  padding: 20px;
  border: 1px grey dashed;
  background: rgba(0, 0, 0, 0.06);
}
/* Horaires */
.week-hours .day {
  background: #fff;
  padding: 8px 0;
  font-size: 16px;
}
@media screen and (max-width: 350px) {
  .week-hours .day {
    font-size: 14px;
  }
}
.week-hours .day span {
  width: 120px;
  display: inline-block;
  font-weight: 700;
}
@media screen and (max-width: 991px) {
  .week-hours .day span {
    width: 90px;
  }
}
@media screen and (max-width: 350px) {
  .week-hours .day span {
    width: 80px;
  }
}
.week-hours .day.active {
  font-family: 'Chelsea Market', cursive;
  font-size: 20px;
  background: #4d76bb !important;
  color: #fff;
  border-radius: 4px;
  padding: 8px;
}
@media screen and (max-width: 991px) {
  .week-hours .day.active {
    font-size: 15px;
  }
}
@media screen and (max-width: 350px) {
  .week-hours .day.active {
    font-size: 13px;
  }
}
.week-hours .day.active span {
  font-weight: normal;
}
/* Colonnes hauteur égale */
.eqWrap {
  display: flex;
  flex-wrap: wrap;
}
/* Margin */
.margin-top5 {
  margin-top: 5px !important;
}
.margin-top10 {
  margin-top: 10px !important;
}
.margin-top15 {
  margin-top: 15px !important;
}
.margin-top20 {
  margin-top: 20px !important;
}
.margin-top25 {
  margin-top: 25px !important;
}
.margin-top30 {
  margin-top: 30px !important;
}
.margin-top35 {
  margin-top: 35px !important;
}
.margin-top40 {
  margin-top: 40px !important;
}
.margin-top45 {
  margin-top: 45px !important;
}
.margin-top50 {
  margin-top: 50px !important;
}
.margin-bottom5 {
  margin-bottom: 5px !important;
}
.margin-bottom10 {
  margin-bottom: 10px !important;
}
.margin-bottom15 {
  margin-bottom: 15px !important;
}
.margin-bottom20 {
  margin-bottom: 20px !important;
}
.margin-bottom25 {
  margin-bottom: 25px !important;
}
.margin-bottom30 {
  margin-bottom: 30px !important;
}
.margin-bottom35 {
  margin-bottom: 35px !important;
}
.margin-bottom40 {
  margin-bottom: 40px !important;
}
.margin-bottom45 {
  margin-bottom: 45px !important;
}
.margin-bottom50 {
  margin-bottom: 50px !important;
}
/* Visible/Hidden */
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg,
.visible-xl {
  display: none !important;
}
@media (max-width: 575px) {
  .visible-xs {
    display: block !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .visible-sm {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-md {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-lg {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-xl {
    display: block !important;
  }
}
@media (max-width: 575px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-lg {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-xl {
    display: none !important;
  }
}
/** Vidéos responsive **/
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* Color Modal */
.modal-backdrop {
  background-color: #000;
}
.modal-backdrop.show {
  opacity: 0.7;
}
/** Tooltip **/
.tooltip.show {
  opacity: 1;
}
.tooltip-inner {
  background-color: #333;
}
.bs-tooltip-right .arrow:before {
  border-right-color: #333 !important;
}
.bs-tooltip-left .arrow:before {
  border-left-color: #333 !important;
}
.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #333 !important;
}
.bs-tooltip-top .arrow:before {
  border-top-color: #333 !important;
}
/** SFBadminbar **/
#sfbadminbar-body {
  margin-top: 0!important;
}
#sfbadminbar-body .position-sticky {
  top: 32px;
}
/** ReCaptcha V3 hidden **/
/* Small Devices, Tablets annule animations */
@media only screen and (max-width: 768px) {
  .animated {
    /*CSS transitions*/
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    /*CSS transforms*/
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}
/************************************************************
******** STYLE DES IMAGES
************************************************************/
.img-left {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  max-width: 250px;
  height: auto !important;
}
.img-right {
  float: right;
  margin-left: 10px;
  margin-bottom: 10px;
  max-width: 250px;
  height: auto !important;
}
.img-inline {
  display: inline-block;
  width: 32% !important;
  margin: 0.5%;
  height: auto !important;
}
.img-responsive {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  height: auto !important;
}
@media screen and (max-width: 768px) {
  .img-inline {
    display: block;
    width: 100%;
    margin: 0 auto;
    max-width: 400px;
  }
}
@media screen and (max-width: 450px) {
  .img-left {
    float: none;
    margin-right: 0;
    margin-bottom: 15px;
    max-width: 100%;
  }
  .img-right {
    float: none;
    margin-left: 0;
    margin-bottom: 15px;
    max-width: 100%;
  }
}
/***********************************************************
******* ATTENTE
***********************************************************/
#attente {
  padding: 60px 0;
}
#attente h1 {
  font-size: 30px;
  line-height: 32px;
}
#attente h2 {
  font-size: 20px;
  line-height: 22px;
  color: #4d76bb;
  margin-bottom: 10px;
  margin-top: 40px;
}
/***********************************************************
******* HEADER
***********************************************************/
@media screen and (max-width: 767px) {
  #header .info {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }
}
#header .info:hover .icon {
  height: 74px;
  line-height: 74px;
  width: 64px;
  border-radius: 50% / 10%;
  margin-top: 3px;
  margin-bottom: 3px;
}
@media screen and (max-width: 991px) {
  #header .info:hover .icon {
    height: 44px;
    line-height: 44px;
    width: 34px;
  }
}
#header .info:hover .icon:after {
  top: 6px;
  bottom: 6px;
  left: -6px;
  right: -6px;
  border-radius: 10% / 50%;
}
@media screen and (max-width: 991px) {
  #header .info:hover .icon:after {
    top: 3px;
    bottom: 3px;
    left: -3px;
    right: -3px;
  }
}
#header .info .icon {
  font-size: 25px;
  height: 70px;
  line-height: 70px;
  width: 64px;
  border-radius: 50% / 10%;
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 1;
  color: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 991px) {
  #header .info .icon {
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    width: 34px;
  }
}
@media screen and (max-width: 575px) {
  #header .info .icon {
    display: none;
  }
}
#header .info .icon:after {
  content: '';
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: -4px;
  right: -4px;
  background: inherit;
  border-radius: 6% / 50%;
  z-index: -1;
}
@media screen and (max-width: 991px) {
  #header .info .icon:after {
    top: 3px;
    bottom: 3px;
    left: -2px;
    right: -2px;
  }
}
#header .info .text {
  margin-top: 10px;
  font-size: 16px;
  line-height: 18px;
}
@media screen and (max-width: 767px) {
  #header .info .text {
    margin-top: 0;
    margin-left: 10px;
    text-align: left;
    font-size: 14px;
    line-height: 16px;
  }
}
@media screen and (max-width: 575px) {
  #header .info .text {
    text-align: center;
    font-size: 22px;
    line-height: 22px;
    font-family: 'Chelsea Market', cursive;
    margin-left: 0;
    margin-top: 6px;
  }
}
#header .info .text a {
  display: block;
  color: #444;
}
@media screen and (max-width: 575px) {
  #header .info .text a:nth-child(2) {
    display: none;
  }
}
#header .info.bg-vert .icon {
  background: #4ebd9f;
}
#header .info.bg-rose .icon {
  background: #d861a3;
}
#header .info.bg-rose:hover .icon,
#header .info.bg-vert:hover .icon {
  background: #4d76bb;
}
#header .info.bg-rose .text a:hover,
#header .info.bg-vert .text a:hover {
  color: #4d76bb;
}
#header a.logo {
  display: block;
  width: 300px;
  margin: 10px auto 0 auto;
}
@media screen and (max-width: 1199px) {
  #header a.logo {
    width: 280px;
  }
}
@media screen and (max-width: 991px) {
  #header a.logo {
    width: 210px;
  }
}
@media screen and (max-width: 767px) {
  #header a.logo {
    width: 150px;
  }
}
/***********************************************************
******* MENU NAV BOOTSTRAP
***********************************************************/
.navbar.position-sticky {
  top: 0;
  z-index: 9999;
}
.navbar-light {
  background: #fff;
  border-bottom: 4px solid rgba(236, 131, 123, 0.4);
}
.navbar-light .navbar-brand {
  /* Par défaut caché, voir media queries plus bas */
  padding-left: 10px;
}
.navbar-light .navbar-nav .nav-link {
  color: #333;
  font-weight: 500;
}
.navbar-light .navbar-nav .nav-link span.bg-link {
  position: relative;
  padding: 2px 10px;
  display: inline-block;
  z-index: 9;
}
.navbar-light .navbar-nav .nav-link span.bg-link:after {
  content: '';
  display: inline-block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  z-index: -1;
  background: #4d76bb;
  -webkit-transition: -webkit-transform 0.4s ease;
  -o-transition: -o-transform 0.4s ease;
  -moz-transition: transform 0.4s ease, -moz-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease, -moz-transform 0.4s ease, -o-transform 0.4s ease;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
}
.navbar-light .navbar-nav .nav-link:hover span.bg-link:after {
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
}
.navbar-light .navbar-nav .nav-link.dropdown-toggle:hover:after,
.navbar-light .navbar-nav .show > .nav-link.dropdown-toggle:after {
  color: #4d76bb;
}
.navbar-light .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-light .navbar-nav .show > .nav-link span.bg-link:after {
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
}
.navbar-light .navbar-nav .active {
  color: #4d76bb;
  font-weight: 700;
}
.navbar-light .navbar-nav .active:hover .nav-link {
  color: #4d76bb !important;
}
.navbar-light .navbar-nav .active > .nav-link {
  color: #4d76bb;
}
.navbar-light .navbar-nav .active > .nav-link span.bg-link:after {
  background: #fff;
}
.navbar-light .navbar-nav .dropdown-menu {
  min-width: 10rem;
  padding: 0;
  margin: 0 0 0 8px;
  font-size: 1rem;
  color: #4d76bb;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 4px solid #4d76bb;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 767px) {
  .navbar-light .navbar-nav .dropdown-menu {
    margin: 0;
  }
}
.navbar-light .navbar-nav .dropdown-menu .dropdown-item {
  padding: 10px 15px;
  clear: both;
  font-weight: 400;
  color: #4d76bb;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.navbar-light .navbar-nav .dropdown-menu .dropdown-item:hover {
  background: #4d76bb;
  color: #fff;
}
.navbar-light .navbar-nav .dropdown-menu .dropdown-item + .dropdown-item {
  border-top: 4px solid #4d76bb;
}
.navbar-light .navbar-nav .dropdown-menu .active {
  color: #fff;
  background: #4d76bb;
}
.navbar-light .navbar-nav .dropdown-menu .active:hover .nav-link {
  color: #fff !important;
  background: #4d76bb !important;
}
.navbar-light .navbar-nav .dropdown-menu .active > .nav-link {
  color: #333;
}
.navbar-light .navbar-toggler {
  border-color: #ccc;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(51,51,51, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
/** Fonction faire disparaitre le logo de la navbar puis réapparaitre en mode burger **/
@media screen and (min-width: 768px) {
  .navbar-light .navbar-brand {
    display: none;
  }
}
/** Fonction Hover Menu **/
/*
.dropdown:hover > .dropdown-menu {
    display: block;
}
.dropdown > .dropdown-toggle:active {
    pointer-events: none;
}
*/
/***********************************************************
******* CAROUSEL BOOTSTRAP
***********************************************************/
/** Pour l'exemple : suppr ensuite **/
.skyblue {
  background-color: #22c8ff;
}
.deepskyblue {
  background-color: #00bfff;
}
.darkerskyblue {
  background-color: #00a6dd;
}
.carousel-caption h3,
.carousel .icon-container,
.carousel-caption button {
  background-color: #09c;
}
.carousel-caption h3 {
  padding: 0.5em;
}
.carousel .icon-container {
  display: inline-block;
  font-size: 25px;
  line-height: 25px;
  padding: 1em;
  text-align: center;
  border-radius: 50%;
}
.carousel-caption button {
  border-color: #00bfff;
  margin-top: 1em;
}
/** Carousel indicators & Carousel control **/
.carousel-indicators {
  bottom: 0;
  cursor: pointer;
}
.carousel-control.right,
.carousel-control.left {
  background-image: none;
}
/** Carousel items **/
.carousel .carousel-item {
  min-height: 350px;
  height: 100%;
  width: 100%;
}
/*** Fade effect ***/
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
/* Animation delays */
.carousel-caption h3:first-child,
.carousel-caption .slider-title {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.carousel-caption h3:nth-child(2),
.carousel-caption .slider-caption {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.carousel-caption .btn-default {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}
.carousel-caption .btn-inverse {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
#slider_home .carousel-item {
  position: relative;
  height: 650px;
}
@media screen and (max-width: 1399px) {
  #slider_home .carousel-item {
    height: 500px;
  }
}
@media screen and (max-width: 767px) {
  #slider_home .carousel-item {
    height: 400px;
  }
}
#slider_home .carousel-item .carousel-caption {
  position: absolute;
  /* Si on veut centrer la légende
			top: 50%;
			transform: translateY(-50%);
			*/
  top: 130px;
  right: inherit;
  width: 40%;
  bottom: inherit;
  left: 10%;
  z-index: 10;
  padding-top: 0;
  padding-bottom: 0;
  color: #fff;
  text-align: left;
}
@media screen and (max-width: 1399px) {
  #slider_home .carousel-item .carousel-caption {
    width: 55%;
    left: 5%;
    top: 100px;
  }
}
@media screen and (max-width: 1199px) {
  #slider_home .carousel-item .carousel-caption {
    top: 80px;
  }
}
@media screen and (max-width: 767px) {
  #slider_home .carousel-item .carousel-caption {
    top: 50%;
    transform: translateY(-50%);
    right: 5%;
    width: auto;
  }
}
#slider_home .carousel-item .carousel-caption .slider-title {
  display: inline-block;
  padding: 10px 15px;
  font-family: 'Chelsea Market', cursive;
  font-size: 55px;
  line-height: 55px;
  background: #ec837b;
  color: #fff;
  margin-bottom: 30px;
  border-radius: 4px;
}
@media screen and (max-width: 1199px) {
  #slider_home .carousel-item .carousel-caption .slider-title {
    font-size: 45px;
    line-height: 45px;
  }
}
@media screen and (max-width: 767px) {
  #slider_home .carousel-item .carousel-caption .slider-title {
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 0;
    border-radius: 4px 4px 0 0;
  }
}
@media screen and (max-width: 450px) {
  #slider_home .carousel-item .carousel-caption .slider-title {
    font-size: 25px;
    line-height: 25px;
  }
}
#slider_home .carousel-item .carousel-caption .slider-caption {
  font-size: 25px;
  line-height: 27px;
  color: #ec837b;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  #slider_home .carousel-item .carousel-caption .slider-caption {
    background: #fff;
    padding: 20px;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 22px;
    border-radius: 0 0 4px 4px;
  }
}
@media screen and (max-width: 450px) {
  #slider_home .carousel-item .carousel-caption .slider-caption {
    font-size: 16px;
    line-height: 18px;
  }
}
#slider_home .carousel-item .carousel-caption .btn-slider {
  display: inline-block;
  padding: 20px 25px;
  border-radius: 50% / 15%;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 15px;
  color: #fff;
  z-index: 1;
  font-size: 24px;
  text-transform: capitalize;
  transition: all linear 0.1s;
  -o-transition: all linear 0.1s;
  -moz-transition: all linear 0.1s;
  -webkit-transition: all linear 0.1s;
  margin-left: 5px;
  cursor: pointer;
  filter: drop-shadow(0 5px 10px rgba(132, 194, 235, 0.3));
  background: red;
}
#slider_home .carousel-item .carousel-caption .btn-slider:before {
  content: '';
  position: absolute;
  border-radius: 3% / 50%;
  background: inherit;
  top: 10px;
  bottom: 10px;
  right: -5px;
  left: -5px;
  z-index: -1;
  transition: all linear 0.1s;
  -o-transition: all linear 0.1s;
  -moz-transition: all linear 0.1s;
  -webkit-transition: all linear 0.1s;
}
#slider_home .carousel-item .carousel-caption .btn-slider:hover {
  padding: 23px 25px;
  border-radius: 50% / 18%;
  margin-top: 7px;
  margin-bottom: 7px;
  color: #fff;
  animation: rainbow 5s infinite alternate;
  -moz-animation: rainbow 5s infinite alternate;
  -webkit-animation: rainbow 5s infinite alternate;
  -o-animation: rainbow 5s infinite alternate;
  background: blue;
}
#slider_home .carousel-item .carousel-caption .btn-slider:hover:before {
  border-radius: 6% / 50%;
  top: 11px;
  bottom: 11px;
  right: -9px;
  left: -9px;
  animation: rainbow 5s infinite alternate;
  -moz-animation: rainbow 5s infinite alternate;
  -webkit-animation: rainbow 5s infinite alternate;
  -o-animation: rainbow 5s infinite alternate;
}
#slider_home .carousel-item .content {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
}
#slider_home .carousel-item .content .left {
  background: #f7cdca;
  width: 40%;
  height: 100%;
}
@media screen and (max-width: 1399px) {
  #slider_home .carousel-item .content .left {
    width: 60%;
  }
}
#slider_home .carousel-item .content .right {
  position: relative;
  background: url('../img/big_img_test.jpg') no-repeat center center;
  background-size: cover;
  width: 60%;
  height: 100%;
}
@media screen and (max-width: 1399px) {
  #slider_home .carousel-item .content .right {
    width: 40%;
  }
}
#slider_home .carousel-item .content .right:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../img/slider-mask.png') no-repeat left top;
  background-size: cover;
}
/***********************************************************
******* PRESENTATION HOME
***********************************************************/
#presentation-home {
  padding: 100px 0;
}
@media screen and (max-width: 575px) {
  #presentation-home {
    padding: 60px 0;
  }
}
#presentation-home h1 {
  font-size: 25px;
  line-height: 27px;
  margin-bottom: 20px;
}
#presentation-home h1 span {
  display: table;
  margin-bottom: 15px;
  font-family: 'Chelsea Market', cursive;
  background: #ec837b;
  color: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 35px;
  line-height: 35px;
}
#presentation-home .img-form {
  border-radius: 50% / 10%;
  clip-path: inset(0% 0% 0% 0% round 8% / 50%);
}
@media screen and (max-width: 767px) {
  #presentation-home .img-form {
    margin-top: 30px;
  }
}
#presentation-home .text p:last-child {
  margin-bottom: 0;
}
/***********************************************************
******* ACTUALITES HOME
***********************************************************/
#actualites-home {
  padding: 100px 0;
  color: #fff;
  background: url(../img/big_img_test.jpg) no-repeat;
  background-size: cover;
  position: relative;
  /** Correctif fixed sur version mobile **/
  /** Tiny controls **/
}
@media screen and (max-width: 575px) {
  #actualites-home {
    padding: 60px 0;
  }
}
@media only screen and (min-width: 992px) {
  #actualites-home {
    background-attachment: fixed;
  }
}
#actualites-home .container {
  position: relative;
  z-index: 9;
}
#actualites-home h2 {
  font-size: 25px;
  line-height: 27px;
  margin-bottom: 20px;
}
#actualites-home h2 span {
  display: table;
  margin-bottom: 15px;
  font-family: 'Chelsea Market', cursive;
  color: #ec837b;
  background: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 35px;
  line-height: 35px;
}
#actualites-home .tiny-actus {
  color: #444;
}
#actualites-home .tiny-actus .item a.content {
  position: relative;
  display: block;
  color: #444;
  min-height: 300px;
  background: #fff;
  border-radius: 50% / 10%;
  clip-path: inset(0% 0% 0% 0% round 8% / 50%);
  padding: 30px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  #actualites-home .tiny-actus .item a.content {
    min-height: inherit;
  }
}
#actualites-home .tiny-actus .item a.content:hover .date {
  top: 16px;
  height: 114px;
  border-radius: 50% / 14%;
  padding-top: 24px;
  background: #ec837b;
}
#actualites-home .tiny-actus .item a.content:hover .date:before {
  top: 11px;
  bottom: 11px;
  left: -10px;
  right: -10px;
  border-radius: 12% / 50%;
  background: #ec837b;
}
#actualites-home .tiny-actus .item a.content:hover p.info {
  color: #ec837b;
}
#actualites-home .tiny-actus .item a.content .date {
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 50%;
  width: 106px;
  height: 106px;
  border-radius: 50% / 10%;
  text-align: center;
  margin: 3px 0 0 3px;
  margin-left: -53px;
  padding-top: 20px;
  font-family: 'Chelsea Market', cursive;
  font-size: 40px;
  line-height: 35px;
  color: #fff;
  background: #4d76bb;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#actualites-home .tiny-actus .item a.content .date:before {
  background: #4d76bb;
  top: 8px;
  bottom: 8px;
  left: -6px;
  right: -6px;
  content: '';
  position: absolute;
  border-radius: 7% / 50%;
  z-index: -1;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#actualites-home .tiny-actus .item a.content .date span {
  display: block;
  font-size: 30px;
}
#actualites-home .tiny-actus .item a.content h3 {
  padding-top: 114px;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 15px;
}
#actualites-home .tiny-actus .item a.content p.info {
  color: rgba(68, 68, 68, 0.8);
  font-size: 14px;
  line-height: 16px;
}
#actualites-home .tns-controls {
  position: absolute;
  text-align: center;
  outline: none;
  left: -367px;
  top: 240px;
}
@media screen and (max-width: 1199px) {
  #actualites-home .tns-controls {
    left: -306px;
  }
}
@media screen and (max-width: 991px) {
  #actualites-home .tns-controls {
    left: -347px;
    top: 220px;
  }
}
@media screen and (max-width: 767px) {
  #actualites-home .tns-controls {
    left: inherit;
    top: -183px;
    right: 15px;
  }
}
@media screen and (max-width: 500px) {
  #actualites-home .tns-controls {
    position: relative;
    text-align: left;
    top: inherit;
    right: inherit;
    margin: 10px 0;
  }
}
#actualites-home .tns-controls:focus {
  outline: none;
}
#actualites-home .tns-controls button {
  border: 0;
  padding: 0;
  font-size: 28px;
  color: #fff;
  background: #ec837b;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 60px;
  margin: 2px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  #actualites-home .tns-controls button {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
    font-size: 20px;
  }
}
#actualites-home .tns-controls button:hover {
  color: #4d76bb;
  background: #fff;
}
#actualites-home .tns-controls button:disabled {
  color: rgba(255, 255, 255, 0.5);
  background: #ec837b;
}
#actualites-home .tns-controls button:focus {
  outline: none;
}
#actualites-home .parallax-black {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: rgba(77, 118, 187, 0.8);
  background: rgba(77, 118, 187, 0.8);
  color: rgba(77, 118, 187, 0.8);
  position: absolute;
  top: 0;
  height: 100%;
}
/***********************************************************
******* TAB HOME
***********************************************************/
#tab-home {
  padding: 80px 0 160px 0;
}
@media screen and (max-width: 575px) {
  #tab-home {
    padding: 60px 0;
  }
}
#tab-home #infos-tab .nav-item .nav-link {
  padding: 0 50px;
}
@media screen and (max-width: 991px) {
  #tab-home #infos-tab .nav-item .nav-link {
    padding: 0 40px;
  }
}
@media screen and (max-width: 767px) {
  #tab-home #infos-tab .nav-item .nav-link {
    padding: 0 30px;
  }
}
@media screen and (max-width: 530px) {
  #tab-home #infos-tab .nav-item .nav-link {
    padding: 0 15px;
  }
}
@media screen and (max-width: 400px) {
  #tab-home #infos-tab .nav-item .nav-link {
    padding: 0 10px;
  }
}
#tab-home #infos-tab .nav-item .nav-link:hover .tab-icon .tab-icon-shape i {
  height: 151px;
  line-height: 151px;
  border-radius: 50% / 9%;
  margin-top: 3px;
  margin-bottom: 3px;
  background: #4d76bb !important;
  filter: drop-shadow(0 0 20px rgba(77, 118, 187, 0.3)) !important;
}
@media screen and (max-width: 767px) {
  #tab-home #infos-tab .nav-item .nav-link:hover .tab-icon .tab-icon-shape i {
    height: 121px;
    line-height: 121px;
  }
}
@media screen and (max-width: 530px) {
  #tab-home #infos-tab .nav-item .nav-link:hover .tab-icon .tab-icon-shape i {
    width: auto;
    height: inherit;
    border-radius: 0;
    margin: 0;
    line-height: 75px;
    background: #fff !important;
    filter: drop-shadow(0 0 0 rgba(78, 189, 159, 0)) !important;
    color: #4d76bb !important;
  }
}
#tab-home #infos-tab .nav-item .nav-link:hover .tab-icon .tab-icon-shape i:after {
  top: 11px;
  bottom: 11px;
  left: -12px;
  right: -12px;
  background: inherit;
  border-radius: 9% / 50%;
}
@media screen and (max-width: 767px) {
  #tab-home #infos-tab .nav-item .nav-link:hover .tab-icon .tab-icon-shape i:after {
    top: 8px;
    bottom: 8px;
    left: -9px;
    right: -9px;
  }
}
@media screen and (max-width: 530px) {
  #tab-home #infos-tab .nav-item .nav-link:hover .tab-icon .tab-icon-shape i:after {
    display: none;
  }
}
#tab-home #infos-tab .nav-item .nav-link:hover .tab-icon span {
  color: #4d76bb !important;
}
#tab-home #infos-tab .nav-item .nav-link .tab-icon {
  text-align: center;
}
#tab-home #infos-tab .nav-item .nav-link .tab-icon .tab-icon-shape i {
  font-size: 75px;
  line-height: 145px;
  width: 135px;
  height: 145px;
  display: inline-block;
  color: #fff;
  border-radius: 50% / 8%;
  position: relative;
  margin-top: 6px;
  margin-bottom: 6px;
  background: #4ebd9f;
  filter: drop-shadow(0 0 20px rgba(78, 189, 159, 0.3));
}
@media screen and (max-width: 767px) {
  #tab-home #infos-tab .nav-item .nav-link .tab-icon .tab-icon-shape i {
    font-size: 55px;
    line-height: 115px;
    width: 105px;
    height: 115px;
  }
}
@media screen and (max-width: 530px) {
  #tab-home #infos-tab .nav-item .nav-link .tab-icon .tab-icon-shape i {
    width: auto;
    height: inherit;
    border-radius: 0;
    margin: 0;
    line-height: 75px;
    background: #fff !important;
    filter: drop-shadow(0 0 0 rgba(78, 189, 159, 0));
  }
}
#tab-home #infos-tab .nav-item .nav-link .tab-icon .tab-icon-shape i:after {
  top: 11px;
  bottom: 11px;
  left: -10px;
  right: -10px;
  background: inherit;
  border-radius: 7% / 50%;
  position: absolute;
  content: '';
  z-index: -1;
}
@media screen and (max-width: 767px) {
  #tab-home #infos-tab .nav-item .nav-link .tab-icon .tab-icon-shape i:after {
    top: 8px;
    bottom: 8px;
    left: -7px;
    right: -7px;
  }
}
@media screen and (max-width: 530px) {
  #tab-home #infos-tab .nav-item .nav-link .tab-icon .tab-icon-shape i:after {
    display: none;
  }
}
#tab-home #infos-tab .nav-item .nav-link .tab-icon span {
  font-family: 'Chelsea Market', cursive;
  font-size: 22px;
  text-transform: capitalize;
  line-height: 26px;
  padding-top: 5px;
  display: inline-block;
  color: #4ebd9f;
}
@media screen and (max-width: 767px) {
  #tab-home #infos-tab .nav-item .nav-link .tab-icon span {
    font-size: 16px;
    line-height: 20px;
  }
}
#tab-home #infos-tab .nav-item .nav-link .tab-icon.bg-rose .tab-icon-shape i {
  background: #d861a3;
  filter: drop-shadow(0 0 20px rgba(216, 97, 163, 0.3));
}
@media screen and (max-width: 530px) {
  #tab-home #infos-tab .nav-item .nav-link .tab-icon.bg-rose .tab-icon-shape i {
    background: #fff;
    color: #d861a3;
    filter: drop-shadow(0 0 0 rgba(216, 97, 163, 0));
  }
}
#tab-home #infos-tab .nav-item .nav-link .tab-icon.bg-rose span {
  color: #d861a3;
}
#tab-home #infos-tab .nav-item .nav-link .tab-icon.bg-vert .tab-icon-shape i {
  background: #4ebd9f;
  filter: drop-shadow(0 0 20px rgba(78, 189, 159, 0.3));
}
@media screen and (max-width: 530px) {
  #tab-home #infos-tab .nav-item .nav-link .tab-icon.bg-vert .tab-icon-shape i {
    background: #fff;
    color: #4ebd9f;
    filter: drop-shadow(0 0 0 rgba(78, 189, 159, 0));
  }
}
#tab-home #infos-tab .nav-item .nav-link .tab-icon.bg-vert span {
  color: #4ebd9f;
}
#tab-home #infos-tab .nav-item .nav-link .tab-icon.bg-jaune .tab-icon-shape i {
  background: #ffad2e;
  filter: drop-shadow(0 0 20px rgba(255, 173, 46, 0.3));
}
@media screen and (max-width: 530px) {
  #tab-home #infos-tab .nav-item .nav-link .tab-icon.bg-jaune .tab-icon-shape i {
    background: #fff;
    color: #ffad2e;
    filter: drop-shadow(0 0 0 rgba(255, 173, 46, 0));
  }
}
#tab-home #infos-tab .nav-item .nav-link .tab-icon.bg-jaune span {
  color: #ffad2e;
}
#tab-home #infos-tab .nav-item .nav-link.active .tab-icon .tab-icon-shape i {
  height: 151px;
  line-height: 151px;
  border-radius: 50% / 9%;
  margin-top: 3px;
  margin-bottom: 3px;
  background: #ec837b !important;
  filter: drop-shadow(0 0 20px rgba(236, 131, 123, 0.3)) !important;
}
@media screen and (max-width: 767px) {
  #tab-home #infos-tab .nav-item .nav-link.active .tab-icon .tab-icon-shape i {
    height: 121px;
    line-height: 121px;
  }
}
@media screen and (max-width: 530px) {
  #tab-home #infos-tab .nav-item .nav-link.active .tab-icon .tab-icon-shape i {
    width: auto;
    height: inherit;
    border-radius: 0;
    margin: 0;
    line-height: 75px;
    background: #fff !important;
    filter: drop-shadow(0 0 0 rgba(78, 189, 159, 0)) !important;
    color: #ec837b !important;
  }
}
#tab-home #infos-tab .nav-item .nav-link.active .tab-icon .tab-icon-shape i:after {
  top: 11px;
  bottom: 11px;
  left: -12px;
  right: -12px;
  background: inherit;
  border-radius: 9% / 50%;
}
@media screen and (max-width: 767px) {
  #tab-home #infos-tab .nav-item .nav-link.active .tab-icon .tab-icon-shape i:after {
    top: 8px;
    bottom: 8px;
    left: -9px;
    right: -9px;
  }
}
@media screen and (max-width: 530px) {
  #tab-home #infos-tab .nav-item .nav-link.active .tab-icon .tab-icon-shape i:after {
    display: none;
  }
}
#tab-home #infos-tab .nav-item .nav-link.active .tab-icon span {
  color: #ec837b !important;
}
#tab-home #infos-tab-content .tab-pane {
  padding-top: 60px;
}
@media screen and (max-width: 767px) {
  #tab-home #infos-tab-content .tab-pane .col-md-6 + .col-md-6 {
    margin-top: 20px;
  }
}
#tab-home #infos-tab-content .subtitle {
  font-weight: 500;
  margin-bottom: 16px;
}
#tab-home #infos-tab-content h2 {
  font-size: 32px;
  line-height: 34px;
}
@media screen and (max-width: 991px) {
  #tab-home #infos-tab-content h2 {
    font-size: 28px;
    line-height: 30px;
  }
}
#tab-home #infos-tab-content h2 + p,
#tab-home #infos-tab-content h2 + .gmap-iframe,
#tab-home #infos-tab-content h2 + ul.coordonnees-tab {
  margin-top: 16px;
}
#tab-home #infos-tab-content p:last-child {
  margin-bottom: 0;
}
#tab-home #infos-tab-content .gmap-iframe {
  width: 90%;
  height: 200px;
  margin: 0 auto;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  #tab-home #infos-tab-content .gmap-iframe {
    width: 100%;
  }
}
#tab-home #infos-tab-content .gmap-iframe iframe {
  width: 100%;
  height: 100%;
}
#tab-home #infos-tab-content ul.coordonneees-tab {
  background: #f5f5f5;
  width: 90%;
  margin: 0 auto 16px auto;
  padding: 16px 0;
  list-style: none;
  border-radius: 0 0 4px 4px;
}
@media screen and (max-width: 991px) {
  #tab-home #infos-tab-content ul.coordonneees-tab {
    width: 100%;
  }
}
#tab-home #infos-tab-content ul.coordonneees-tab li {
  font-size: 16px;
  line-height: 28px;
  padding: 0 10px;
}
#tab-home #infos-tab-content ul.coordonneees-tab li a {
  color: #444;
}
#tab-home #infos-tab-content ul.coordonneees-tab li a:hover {
  color: #ec837b;
}
/***********************************************************
******* INT
***********************************************************/
#int {
  padding: 60px 0 140px 0;
}
@media screen and (max-width: 575px) {
  #int {
    padding: 60px 0;
  }
}
#int h1 {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 40px;
  font-style: italic;
}
#int h1 span {
  display: table;
  margin-bottom: 15px;
  font-family: 'Chelsea Market', cursive;
  color: #fff;
  background: #4d76bb;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 35px;
  line-height: 35px;
  font-style: normal;
}
#int h2 {
  font-family: 'Chelsea Market', cursive;
  color: #4d76bb;
  font-size: 25px;
  line-height: 27px;
  margin: 30px 0 16px 0;
}
#int h3 {
  color: #ec837b;
  font-size: 18px;
  line-height: 20px;
  margin: 30px 0 16px 0;
}
#int h2 + h3 {
  margin-top: 10px;
}
#int p + ul {
  margin-top: -16px;
}
#int a {
  color: #4d76bb;
}
#int a:hover {
  color: #ec837b;
}
#int a.retour-link {
  display: block;
  color: #ec837b;
}
#int a.retour-link:hover {
  color: #d861a3;
}
#int h1 + a.retour-link {
  margin-top: -20px;
  margin-bottom: 40px;
}
#int .imgcms-right {
  float: right;
  margin-left: 40px;
  margin-bottom: 40px;
  width: 100%;
  max-width: 350px;
  text-align: center;
}
@media screen and (max-width: 1199px) {
  #int .imgcms-right {
    max-width: 250px;
  }
}
@media screen and (max-width: 575px) {
  #int .imgcms-right {
    float: none;
    margin-left: 0;
    margin-bottom: 20px;
  }
}
#int .imgcms-right img {
  border-radius: 50% / 10%;
  clip-path: inset(0% 0% 0% 0% round 8% / 50%);
}
#int .imgcms-right a:hover section {
  color: #ec837b;
}
#int .imgcms-right a section {
  font-family: 'Chelsea Market', cursive;
  margin-top: 10px;
  color: #4d76bb;
  text-decoration: underline;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 575px) {
  #int .imgcms-right a section {
    display: none;
  }
}
#int .photos-hidden {
  display: none;
}
#int a.btn-default {
  color: #fff;
}
#int a.btn-default:hover {
  color: #fff;
}
#int #sidebar .liens-utiles {
  background: #ffad2e;
  border-radius: 50% / 10%;
  clip-path: inset(0% 0% 0% 0% round 8% / 50%);
  padding: 30px;
  text-align: center;
  margin-bottom: 30px;
}
@media screen and (max-width: 991px) {
  #int #sidebar .liens-utiles {
    margin-top: 40px;
  }
}
#int #sidebar .liens-utiles .title-side {
  display: inline-block;
  margin-bottom: 10px;
  font-family: 'Chelsea Market', cursive;
  color: #fff;
  font-size: 22px;
  line-height: 22px;
}
#int #sidebar .liens-utiles ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#int #sidebar .liens-utiles ul li {
  padding: 10px 5px;
}
#int #sidebar .liens-utiles ul li:last-child {
  padding: 10px 5px 0 5px;
}
#int #sidebar .liens-utiles ul li a {
  color: #fff;
}
#int #sidebar .liens-utiles ul li a:hover {
  color: #4d76bb;
}
#int #sidebar .liens-utiles ul li + li {
  border-top: 1px solid #fa9800;
}
#int #sidebar .horaires {
  text-align: center;
  margin-bottom: 30px;
}
#int #sidebar .horaires .title-side {
  background: #d861a3;
}
#int #sidebar .horaires h2 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-weight: 500 !important;
  font-family: 'Rubik', sans-serif !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #444 !important;
}
#int #sidebar .horaires .title {
  margin-bottom: 15px;
  font-weight: 500;
}
#int #sidebar .horaires .day {
  padding: 2px 0;
}
#int #sidebar .horaires .day span {
  width: 100%;
}
#int #sidebar .horaires .day.active {
  padding: 8px;
  font-size: 16px;
}
#int #sidebar .periscolaire {
  text-align: center;
}
#int #sidebar .periscolaire .title-side {
  background: #4ebd9f;
}
#int #sidebar .horaires .title-side,
#int #sidebar .periscolaire .title-side {
  display: block;
  width: 100%;
  color: #fff;
  border-radius: 50% / 10%;
  clip-path: inset(0% 0% 0% 0% round 8% / 50%);
  padding: 30px;
  text-align: center;
  margin-bottom: 30px;
  font-family: 'Chelsea Market', cursive;
  font-size: 22px;
  line-height: 26px;
}
#int #sidebar .horaires .title-side:hover,
#int #sidebar .periscolaire .title-side:hover {
  background: #4d76bb;
}
#int #sidebar .horaires .title-side i,
#int #sidebar .periscolaire .title-side i {
  display: block;
  font-size: 75px;
}
#int #sidebar .coordonnees {
  position: relative;
  display: block;
  color: #fff;
  background: #ffad2e;
  border-radius: 50% / 10%;
  clip-path: inset(0% 0% 0% 0% round 8% / 50%);
  padding: 30px;
  text-align: center;
  margin-bottom: 30px;
}
@media screen and (max-width: 991px) {
  #int #sidebar .coordonnees {
    margin-top: 40px;
  }
}
#int #sidebar .coordonnees h2 {
  margin-top: 0 !important;
  color: #fff !important;
}
#int #sidebar .coordonnees .gmap-iframe {
  width: 100%;
  height: 150px;
}
#int #sidebar .coordonnees .gmap-iframe iframe {
  width: 100%;
  height: 100%;
}
#int #sidebar .coordonnees ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#int #sidebar .coordonnees ul li {
  padding: 10px 5px;
}
#int #sidebar .coordonnees ul li:last-child {
  padding: 10px 5px 0 5px;
}
#int #sidebar .coordonnees ul li span {
  display: block;
  text-transform: uppercase;
  font-weight: 500;
}
#int #sidebar .coordonnees ul li a {
  color: #fff;
}
#int #sidebar .coordonnees ul li a:hover {
  color: #4d76bb;
}
#int #sidebar .coordonnees ul li i {
  font-size: 12px;
}
#int #sidebar .coordonnees ul li + li {
  border-top: 1px solid #fa9800;
}
#int #sidebar .coordonnees .social-networks .sn {
  background: #fff;
  color: #ffad2e !important;
}
#int #sidebar .coordonnees .social-networks .sn:hover {
  background: #4d76bb;
  color: #fff !important;
}
#int a.actu {
  position: relative;
  display: block;
  color: #fff;
  min-height: 280px;
  background: #4d76bb;
  border-radius: 50% / 10%;
  clip-path: inset(0% 0% 0% 0% round 8% / 50%);
  padding: 30px;
  text-align: center;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  #int a.actu {
    min-height: inherit;
  }
}
#int a.actu:hover .date {
  top: 16px;
  height: 114px;
  border-radius: 50% / 14%;
  padding-top: 24px;
  background: #ec837b;
  color: #fff;
}
@media screen and (max-width: 767px) {
  #int a.actu:hover .date {
    height: 74px;
    padding-top: 18px;
  }
}
#int a.actu:hover .date:before {
  top: 11px;
  bottom: 11px;
  left: -10px;
  right: -10px;
  border-radius: 12% / 50%;
  background: #ec837b;
}
@media screen and (max-width: 767px) {
  #int a.actu:hover .date:before {
    top: 8px;
    bottom: 8px;
    left: -7px;
    right: -7px;
  }
}
#int a.actu:hover p.info {
  color: #ec837b;
}
#int a.actu .date {
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 50%;
  width: 106px;
  height: 106px;
  border-radius: 50% / 10%;
  text-align: center;
  margin: 3px 0 0 3px;
  margin-left: -53px;
  padding-top: 20px;
  font-family: 'Chelsea Market', cursive;
  font-size: 40px;
  line-height: 35px;
  color: #4d76bb;
  background: #fff;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  #int a.actu .date {
    width: 66px;
    height: 66px;
    margin-left: -33px;
    font-size: 25px;
    line-height: 20px;
    padding-top: 14px;
  }
}
#int a.actu .date:before {
  background: #fff;
  top: 8px;
  bottom: 8px;
  left: -6px;
  right: -6px;
  content: '';
  position: absolute;
  border-radius: 7% / 50%;
  z-index: -1;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  #int a.actu .date:before {
    top: 6px;
    bottom: 6px;
    left: -5px;
    right: -5px;
  }
}
#int a.actu .date span {
  display: block;
  font-size: 30px;
}
@media screen and (max-width: 767px) {
  #int a.actu .date span {
    font-size: 20px;
  }
}
#int a.actu h2 {
  padding-top: 94px;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 15px;
  color: #fff;
  font-family: 'Rubik', sans-serif;
}
@media screen and (max-width: 767px) {
  #int a.actu h2 {
    padding-top: 40px;
    font-size: 17px;
    line-height: 21px;
  }
}
#int a.actu p.info {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 0;
}
/***********************************************************
******* FORMULAIRES (avec Parsley)
***********************************************************/
label {
  margin: 0;
  font-family: 'Chelsea Market', cursive;
  color: #4d76bb;
}
textarea.form-control {
  height: auto;
}
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #444;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control:focus {
  border-color: #4d76bb;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(77, 118, 187, 0);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(77, 118, 187, 0);
}
.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #999;
}
.form-control::-webkit-input-placeholder {
  color: #999;
}
.parsley-success {
  border: solid 1px #ccc !important;
  background: #fff !important;
  color: #333 !important;
}
.parsley-error {
  border: solid 1px #AA102A !important;
  background: rgba(170, 16, 42, 0.2) !important;
  color: #000 !important;
}
.parsley-errors-list {
  color: #AA102A;
  list-style: none;
  padding: 0;
}
.red {
  color: #ec837b;
  list-style: none;
  padding: 0;
}
#form_contact .required {
  color: #4d76bb;
  list-style: none;
  padding: 0;
}
#form_contact .success {
  color: #444;
}
#form_contact label.required:after {
  content: " *";
  color: #ec837b;
  position: absolute;
  margin: 0 0 0 2px;
  font-size: 14px;
  padding: 0 5px 0 0;
}
.ui-datepicker .ui-datepicker-title select {
  color: #333;
}
.parsley-error::-webkit-input-placeholder {
  color: #333;
}
.parsley-error:-moz-placeholder {
  color: #333;
}
.parsley-error::-moz-placeholder {
  color: #333;
}
.parsley-error:-ms-input-placeholder {
  color: #333;
}
/***********************************************************
******* FOOTER
***********************************************************/
#footer .footer-up .container {
  position: relative;
}
@media screen and (max-width: 575px) {
  #footer .footer-up .container {
    padding: 0;
  }
}
#footer .footer-up .preinscription {
  position: absolute;
  top: -80px;
  width: 100%;
  background: #fff;
  border-radius: 50% / 10%;
  padding: 35px 0;
  min-height: 200px;
}
@media screen and (max-width: 991px) {
  #footer .footer-up .preinscription {
    min-height: 180px;
  }
}
@media screen and (max-width: 767px) {
  #footer .footer-up .preinscription {
    padding: 35px 0 25px 0;
  }
}
@media screen and (max-width: 575px) {
  #footer .footer-up .preinscription {
    position: relative;
    top: inherit;
    margin: 0;
    background: transparent;
    border-radius: 0;
  }
}
#footer .footer-up .preinscription:before {
  top: 20px;
  bottom: 20px;
  left: -11px;
  right: -11px;
  border-radius: 1% / 50%;
  background: #fff;
  position: absolute;
  content: '';
}
@media screen and (max-width: 1199px) {
  #footer .footer-up .preinscription:before {
    left: -9px;
    right: -9px;
  }
}
@media screen and (max-width: 991px) {
  #footer .footer-up .preinscription:before {
    left: -6px;
    right: -6px;
    top: 17px;
    bottom: 17px;
  }
}
@media screen and (max-width: 767px) {
  #footer .footer-up .preinscription:before {
    left: -5px;
    right: -5px;
    top: 21px;
    bottom: 21px;
  }
}
@media screen and (max-width: 575px) {
  #footer .footer-up .preinscription:before {
    display: none;
  }
}
#footer .footer-up .preinscription .content {
  z-index: 9;
}
@media screen and (max-width: 767px) {
  #footer .footer-up .preinscription .content {
    text-align: center !important;
  }
}
#footer .footer-up .preinscription .content .etiquette {
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  padding-left: 10px;
}
#footer .footer-up .preinscription .content .title {
  color: #fff;
  font-size: 35px;
  line-height: 37px;
  font-weight: 500;
  padding-left: 10px;
}
@media screen and (max-width: 991px) {
  #footer .footer-up .preinscription .content .title {
    font-size: 28px;
    line-height: 30px;
  }
}
@media screen and (max-width: 767px) {
  #footer .footer-up .preinscription .content .title {
    font-size: 22px;
    line-height: 24px;
    padding-left: 0;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 400px) {
  #footer .footer-up .preinscription .content .title {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 5px;
  }
}
#footer .footer-up .preinscription .content .btn-default {
  text-transform: inherit;
  background: #fff;
  color: #ec837b;
}
#footer .footer-up .preinscription .content .btn-default:hover {
  background: #fff;
  color: #4d76bb;
}
#footer .footer-up .preinscription .preinscription-bg {
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  top: 6px;
  left: 6px;
  border-radius: 50% / 10%;
  position: absolute;
  background: #ec837b;
}
@media screen and (max-width: 575px) {
  #footer .footer-up .preinscription .preinscription-bg {
    width: 100%;
    left: 0;
  }
}
#footer .footer-up .preinscription .preinscription-bg:before {
  top: 18px;
  bottom: 18px;
  left: -11px;
  right: -11px;
  border-radius: 1% / 50%;
  background: #ec837b;
  position: absolute;
  content: '';
}
@media screen and (max-width: 1199px) {
  #footer .footer-up .preinscription .preinscription-bg:before {
    left: -9px;
    right: -9px;
  }
}
@media screen and (max-width: 991px) {
  #footer .footer-up .preinscription .preinscription-bg:before {
    left: -7px;
    right: -7px;
    top: 16px;
    bottom: 16px;
  }
}
@media screen and (max-width: 767px) {
  #footer .footer-up .preinscription .preinscription-bg:before {
    left: -5px;
    right: -5px;
    top: 21px;
    bottom: 21px;
  }
}
@media screen and (max-width: 575px) {
  #footer .footer-up .preinscription .preinscription-bg:before {
    display: none;
  }
}
#footer .footer-down {
  background: #4d76bb;
  padding: 190px 0 20px 0;
  color: #f5f5f5;
}
@media screen and (max-width: 575px) {
  #footer .footer-down {
    margin-top: -30px;
    padding-top: 80px;
    text-align: center;
  }
}
#footer .footer-down .title-ecole {
  margin-bottom: 15px;
  font-family: 'Chelsea Market', cursive;
  color: #fff;
  font-size: 35px;
  line-height: 35px;
}
@media screen and (min-width: 576px) {
  #footer .footer-down .title-ecole .img-responsive {
    margin: inherit;
  }
}
#footer .footer-down .title {
  display: table;
  margin-bottom: 15px;
  font-family: 'Chelsea Market', cursive;
  color: #4d76bb;
  background: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 25px;
  line-height: 25px;
}
@media screen and (max-width: 991px) {
  #footer .footer-down .title {
    margin-top: 30px;
  }
}
@media screen and (max-width: 575px) {
  #footer .footer-down .title {
    display: none;
  }
}
#footer .footer-down p {
  margin-bottom: 0;
}
#footer .footer-down ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media screen and (max-width: 575px) {
  #footer .footer-down ul {
    margin-top: 15px;
  }
}
#footer .footer-down ul li i {
  width: 20px;
}
#footer .footer-down hr {
  margin-top: 60px;
  margin-bottom: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
@media screen and (max-width: 575px) {
  #footer .footer-down hr {
    margin-top: 40px;
  }
}
#footer .footer-down a.logo-footer {
  display: block;
  max-width: 200px;
  margin: 0 auto;
}
@media screen and (max-width: 991px) {
  #footer .footer-down a.logo-footer {
    margin-top: 30px;
  }
}
#footer .footer-down a {
  color: #f5f5f5;
}
#footer .footer-down a:hover {
  color: #ffad2e;
}
#footer .footer-down a i {
  width: inherit !important;
}
